import React, { Fragment, useCallback, useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getCategories } from "../../../../../api/redux/slices/AdminSlices";
import {
  newImage,
  singleProduct,
  updateProduct,
} from "../../../../../api/redux/slices/ProductSlices";
import { Icons } from "../../../../assets/icons";
import "./index.scss";

const UpdateProductAdmin = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { product } = useSelector((state) => state.products);
  const { loading } = useSelector((state) => state.products);
  const { categories } = useSelector((state) => state.admin);
  const [productToUpdate, setProductToUpdate] = useState(product.list);

  const [imageUp, setImageUp] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(product?.images?.secure_url);
  const [image, setImage] = useState(product?.images);
  const token = localStorage.getItem("accesstoken");

  const [values, setValues] = useState({
    name: productToUpdate?.name,
    category: productToUpdate?.category,
    reference: productToUpdate?.reference,
    description: productToUpdate?.description,
    assemblage: productToUpdate?.assemblage,
    stock: productToUpdate?.stock,
    marque: productToUpdate?.marque,
    uniteVente: productToUpdate?.uniteVente
  });

  //---------------------------------UPDATE PRODUCT -----------------------------------------//
  const handleUpdate = (e) => {
    e.preventDefault();
    const data = {
      name: values?.name,
      category: values?.category,
      reference: values?.reference,
      description: values?.description,
      assemblage: values?.assemblage,
      stock: values?.stock,
      uniteVente: values?.uniteVente,
      marque: values?.marque,
      images: image,
    };
    dispatch(updateProduct({ token: token, id: params.id, data: data }))
      .unwrap()
      .then(() => {
        dispatch(singleProduct({ id: params.id }))
        toast.success("Produit Modifié", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        window.scrollTo({ top: 0, behavior: 'smooth' });
      })
      .catch((err) => {
        toast.error(err.msg, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  //------------------------------- GET A SINGLE PRODUCT -----------------------------------//
  const stableOneDispatch = useCallback(() => {
    dispatch(singleProduct({ id: params.id }))
      .unwrap()
      .then((res) => {
        setFileUploaded(res.list.images.secure_url);
        setImage(res.list.images);
        setProductToUpdate(res.list);
      });
  }, [dispatch, params.id]);

  useEffect(() => {
    stableOneDispatch();
  }, [stableOneDispatch]);

  //-------------------------------- GET CATEGORIES LIST ---------------------------------------//
  const stableCategoriesDispatch = useCallback(() => {
    dispatch(getCategories());
  }, [dispatch]);

  useEffect(() => {
    if (params.id) {
      stableCategoriesDispatch();
    }
  }, [stableCategoriesDispatch, params]);
  // ----------------------------------------- UPLOAD IMAGE-----------------------------------------//
  const handleUpload = (e) => {
    setImageUp(false);
    setFileUploaded(URL.createObjectURL(e.target.files[0]));
    const file = e.target.files[0];
    let formData = new FormData();
    formData.append("file", file);
    dispatch(newImage({ token: token, data: formData }))
      .unwrap()
      .then((res) => {
        setFileUploaded(res.secure_url);
        setImage(res);
        toast.info("Image téléchargé", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        toast.error(err.msg, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  //------------------------------ OPEN FOR IMAGE INPUT ------------------------------//
  const open_file = () => {
    setImageUp(true);
  };
  useEffect(() => {
    if (!loading && Object.keys(product).length === 0) {
      navigate(`/products/${params.page}`);
    }
  }, [loading, params, navigate, product]);

  if (loading || Object.keys(product).length === 0) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "20%",
        }}
      >
        <ThreeDots
          ariaLabel="blocks-loading"
          visible={true}
          color="#DB1E1E"
          id="loader-spinner"
        />
      </div>
    );
  }

  return (
    <div className="adminPage">
      <Link to={`/products/${params.page}`}>
        <h4>
          <Icons.BiChevronLeft />
          Retour
        </h4>
      </Link>
      <h1 style={{ fontSize: "1.5rem" }}>Voir ou Modifier un produit</h1>
      <div className="update_product">
        <div className="update_image">
          <div className="imgUpload">
            {imageUp ? (
              <input
                type="file"
                id="imgUploading"
                accept=".png, .svg, .jpg"
                onChange={handleUpload}
                title="clickez pour ajouter une image"
              />
            ) : (
              <div className="img_box">
                <img src={`${fileUploaded}`} alt="product_image" />
              </div>
            )}
          </div>
          <div className="btn_update">
            {imageUp ? (
              <button className="btn--main" onClick={open_file}>
                Annuler
              </button>
            ) : (
              <button className="btn--main" onClick={open_file}>
                Modifier la photo
              </button>
            )}
          </div>
        </div>
        <div className="data_box">
          <form className="form" onSubmit={handleUpdate}>
            <div className="line">
              <label>Nom du produit</label>
              <input
                type="text"
                value={values.name}
                onChange={(e) => setValues({ ...values, name: e.target.value })}
                required
              />
            </div>
            <div className="line">
              <label>Réference</label>
              <input
                type="text"
                value={values.reference}
                onChange={(e) =>
                  setValues({ ...values, reference: e.target.value })
                }
                required
              />
            </div>
            <div className="line">
              <label>Marque</label>
              <input
                type="text"
                value={values.marque}
                onChange={(e) =>
                  setValues({ ...values, marque: e.target.value })
                }
                required
              />
            </div>
            <div className="line">
              <label>Catégorie</label>
              <div className="line">
                <select
                  value={values.category}
                  onChange={(e) =>
                    setValues({ ...values, category: e.target.value })
                  }
                >
                  <option value="">Tout</option>
                  {categories.map((value) => (
                    <Fragment key={value._id}>
                      <option value={value.name}>{value.name}</option>
                    </Fragment>
                  ))}
                </select>
              </div>
            </div>
            <div className="line">
              <label>Stock</label>
              <input
                type="number"
                value={values.stock}
                onChange={(e) =>
                  setValues({ ...values, stock: e.target.value })
                }
                required
              />
            </div>
            <div className="line">
              <label>Unité</label>
              <input
                type="text"
                placeholder="ex: metre"
                value={values.uniteVente}
                onChange={(e) =>
                  setValues({ ...values, uniteVente: e.target.value })
                }
                required
              />
            </div>
            <div className="line">
              <label>Description</label>
              <textarea
                type="text"
                rows={5}
                value={values.description}
                onChange={(e) =>
                  setValues({ ...values, description: e.target.value })
                }
                required
              />
            </div>
            <div className="line">
              <label>Assemblage</label>
              <textarea
                type="text"
                rows={6}
                value={values.assemblage}
                onChange={(e) =>
                  setValues({ ...values, assemblage: e.target.value })
                }
                required
              />
            </div>
            <div className="btns">
              <button className="btn--main" type="submit">
                Confirmer
              </button>
              <button
                className="btn--secondary"
                onClick={() => navigate(`/products/${params.page}`)}
              >
                Annuler
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateProductAdmin;
