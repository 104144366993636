import React, { useState, useRef, useEffect } from "react";
import "./ImageSlider.scss";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";

const ImageSlider = ({ slides }) => {
  const navigate = useNavigate()
  const delay = 4500;
  const [currentIndex, setCurrentIndex] = useState(0);
  const timeoutRef = useRef(null);

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setCurrentIndex((prevIndex) =>
          prevIndex === slides.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [currentIndex, slides.length]);

  const slideStyles = {
    backgroundImage: `url(${slides[currentIndex].url})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100%",
    width: "100%",
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };
  const slideStylesWidthBackground = {
    ...slideStyles,
    backgroundImage: `url(${slides[currentIndex].url})`,
  };

  return (
    <div className={"container"}>
      <div style={slideStylesWidthBackground} id="slideStyles">
        <div className="slide_content">
          <h1>
            La société tunisienne d’equipements de <br /> forages (SOTEF) , est
            pionner dans le <br /> secteur de la commercialisation des <br />
            articles et équipements de forage.
          </h1>
          <button style={{cursor:"pointer"}} onClick={() => navigate('/products/1')} >Voir nos produits</button>
        </div>
      </div>
      <div className={"dotsContainerStyles"}>
        {slides.map((slide, index) => (
          <div
            key={index}
            className={clsx(
              currentIndex === index ? "red" : "white",
              "dotStyles"
            )}
            onClick={() => goToSlide(index)}
          >
            ●
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;
