import React, { useState } from "react";
import "./ShoppingCart.scss";
import { FiTrash2, FiCheckCircle } from "react-icons/fi";
import Cart from "../Home/components/Cart";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, newQuote } from "../../api/redux/slices/ProductSlices";
import { toast } from "react-toastify";
import { getUser } from "../../api/redux/slices/AuthSlices";
import emailjs from "@emailjs/browser";

const ShoppingCart = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const token = localStorage.getItem("accesstoken");

  const [loading, setLoading] = useState(false);
  const [cartList, setCartList] = useState(
    JSON.parse(JSON.stringify(user?.cart))
  );
  const [onChangeQty, setOnChangeQty] = useState(false);

  //------------------------------- CART MUTATIONS -------------------------------------//
  const increment = (e, id, qty) => {
    e.preventDefault();
    cartList.forEach((item) => {
      if (item._id === id) {
        item.quantity = Number(qty);
        setOnChangeQty(false);
      }
    });
    setCartList([...cartList]);
    const data = { cart: cartList };
    dispatch(addToCart({ token: token, data: data }))
      .unwrap()
      .then(() => {
        toast.success("Quantitée ajouté", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        dispatch(getUser({ token: token }))
          .unwrap()
          .then((res) => {
            setCartList(JSON.parse(JSON.stringify(res.user.cart)));
          });
      })
      .catch((err) => {
        toast.error(err.msg, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const deleteAllItems = () => {
    if (window.confirm("Etes vous sur de vouloir vider tout le panier ? ")) {
      setCartList([]);
      const data = { cart: [] };
      dispatch(addToCart({ token: token, data: data }))
        .unwrap()
        .then(() => {
          dispatch(getUser({ token: token }))
            .unwrap()
            .then((res) => {
              setCartList(JSON.parse(JSON.stringify(res.user.cart)));
            });
        })
        .catch((err) => {
          toast.error(err.msg, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };
  const deleteItem = (id) => {
    if (window.confirm("Do you want to delete this item from the cart")) {
      cartList.forEach((item, index) => {
        if (item._id === id) {
          cartList.splice(index, 1);
        }
      });
      setCartList([...cartList]);
      const data = { cart: cartList };
      dispatch(addToCart({ token: token, data: data }))
        .unwrap()
        .then(() => {
          dispatch(getUser({ token: token }))
            .unwrap()
            .then((res) => {
              setCartList(JSON.parse(JSON.stringify(res.user.cart)));
            });
        })
        .catch((err) => {
          toast.error(err.msg, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };
  //------------------------------- SEND A QUOTE -------------------------------------//
  const handleNewQuote = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = { products: user.cart };

    if (user.cart.length === 0) {
      toast.info("Votre panier est vide", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      dispatch(newQuote({ token: token, data: data }))
        .unwrap()
        .then(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
          const templateParams = {
            name: user.name,
            email: user.email,
            notes: "Nouvelle demande de devis",
          };
          emailjs
            .send(
              "service_n6i4x2h",
              "template_0kn8yjp",
              templateParams,
              "ziOpVbT1E7oTP_U16"
            )
            .then(
              () => {
                console.log("success");
              },
              () => {
                alert("failed to send your message, please try again ");
              }
            );
          setLoading(false);
          toast.success("Votre demande de Devis à été envoyé", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };

  return (
    <div className="shopping_cart">
      <div className="shopping_cart_header">
        <h2>Panier</h2>
        <div className="empty_cart">
          <button onClick={deleteAllItems}>
            Videz le panier
            <FiTrash2
              style={{ color: "#FFF", marginLeft: "10px" }}
              className="trash_all_cart"
            />
          </button>
        </div>
      </div>
      <div className="shopping_cart_container">
        {cartList.map((item) => (
          <Cart
            key={item._id}
            product={item}
            increment={increment}
            deleteItem={deleteItem}
            onChangeQty={onChangeQty}
            setOnChangeQty={setOnChangeQty}
          />
        ))}
      </div>
      <div className="quote">
        <button onClick={handleNewQuote}>
          Demandez un Devis
          {loading ? (
            "..."
          ) : (
            <FiCheckCircle
              style={{ fontSize: "1.3rem", color: "#FFF", marginLeft: "10px" }}
            />
          )}
        </button>
      </div>
    </div>
  );
};

export default ShoppingCart;
