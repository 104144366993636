import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { devisList } from "../../../../api/redux/slices/AdminSlices";
import { Icons } from "../../../assets/icons";
import './index.scss'

const DevisListPage = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const token = localStorage.getItem("accesstoken");
  const { quotes } = useSelector((state) => state.admin);

  const [searchInput, setSearchInput] = useState("");
  const [quoteStatus, setQuoteStatus] = useState("");
  const [page, setPage] = useState(Number(params.page));


  //-------------------------------- GET USERS LIST ---------------------------------------//
  const stableDispatch = useCallback(() => {
    dispatch(
      devisList({
        token: token,
        searchValue: searchInput,
        statusValue: quoteStatus,
        page: page,
      })
    );
  }, [dispatch, page, token, quoteStatus, searchInput]);

  useEffect(() => {
    if (token) {
      stableDispatch();
    }
  }, [stableDispatch, token]);

  return (
    <div className="adminPage">
      <div className="navbar">
        <div className="left">
          <div className="nav-search">
            <input
              type="text"
              placeholder="Rechercher ..."
              value={searchInput}
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
            />
          </div>
          <div className="filters">
            <select
              value={quoteStatus}
              onChange={(e) => setQuoteStatus(e.target.value)}
            >
              <option value="">Tout</option>
              <option value="envoyé">Envoyé</option>
              <option value="non envoyé">Non envoyé</option>
            </select>
          </div>
        </div>
      </div>
      <div className="mainContent">
        <table className="content">
          <thead>
            <tr className="titres">
              <td>Utilisateurs</td>
              <td>Société</td>
              <td>Etat</td>
              <td style={{display:'flex', justifyContent:"center"}}>Action</td>
            </tr>
          </thead>
          <tbody>
            {quotes?.map((el, index) => (
              <tr className="row" key={index}>
                <td style={{ textTransform: "uppercase" }}>{el.userName}</td>
                <td>{el.userCompany}</td>
                <td
                  style={
                    el.status === "non envoyé"
                      ? { color: "#DB1E1E" }
                      : { color: "#43D9B8" }
                  }
                >
                  {el.status === "envoyé" ? "Envoyé" : "Non envoyé"}
                </td>
                <td className="last_row_element" style={{justifyContent:'center'}}>
                  <Link to={`/devis/${page}/${el._id}`} className="link-order">
                    <Icons.BsEye className="see-order" />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <div className="count">
          Page <span>{page > 0 && page}</span>
        </div>
        <div className="arrows">
          <Link to={page === 1 ? `/devis/${page}` : `/devis/${page - 1}`}>
            <Icons.BiChevronLeft
              className="arrow"
              onClick={() => {
                page === 1 ? setPage(1) : setPage(page - 1);
              }}
            />
          </Link>
          <Link to={`/devis/${page + 1}`}>
            <Icons.BiChevronRight
              className="arrow"
              onClick={() => {
                page === 0 ? setPage(1) : setPage(page + 1);
              }}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DevisListPage;
