import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deleteProduct,
  productsList,
} from "../../../../api/redux/slices/ProductSlices";
import { Icons } from "./../../../assets/icons/index";
import "./index.scss";

const ProductItemAdmin = (props) => {
  const { _id, name, reference, images } = props.data;
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const token = localStorage.getItem("accesstoken");

  //------------------------------DELETE PRODUCT -------------------------------------//
  const handleDeleteProduct = (e) => {
    e.preventDefault();
    if (window.confirm("Voulez vous vraiment supprimer ce produit")) {
      dispatch(deleteProduct({ token: token, id: _id }))
        .unwrap()
        .then((res) => {
          dispatch(
            productsList({
              searchValue: "",
              categoryValue: "",
              page: params.page,
            })
          );
          toast.success("Produit supprimé", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .catch((err) => {
          toast.info(err.msg, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };

  return (
    <div className="product_container">
      <div className="product_img" style={{ cursor: "default" }}>
        <img src={images.secure_url} alt="product_image" />
      </div>
      <div className="descrip_main">
        <h3>{name}</h3>
        <p>{reference}</p>
      </div>
      <div
        className="container_icon"
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          marginBottom: "14px",
        }}
      >
        <Icons.FiEdit
          onClick={() => {
            navigate(`/products/update/${params.page}/${_id}`);
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        />
        <Icons.FaRegTrashAlt
          style={{ color: "#DB1E1E" }}
          onClick={handleDeleteProduct}
        />
      </div>
    </div>
  );
};

export default ProductItemAdmin;
