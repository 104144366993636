import axios from "../baseUrl";

//------------------------- USER LIST ----------------------------------//
const getUserList = (token, searchValue, blocked, page) => {
  return axios.get(
    `/user/all/?search=${searchValue}&blocked=${blocked}&page=${page}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
//------------------------- GET USER BY HIS ID ----------------------------------//
const getUser = (token, id) => {
  return axios.get(`/user/auth/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

//-------------------------  BLOCK USER ----------------------------------//
const blockUser = (token, id, data) => {
  return axios.put(`/user/block/${id}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
//-------------------------  GET CATEGORY LIST ----------------------------------//
const getCategoriesList = () => {
  return axios.get(`/category/all`);
};
//-------------------------  ADD CATEGORY  ----------------------------------//
const newCategory = (token, data) => {
  return axios.post(`/category/new`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
//-------------------------  EDIT CATEGORY  ----------------------------------//
const editCategory = (token, id, data) => {
  return axios.put(`/category/update/${id}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
//-------------------------  DELETE CATEGORY  ----------------------------------//
const delCategory = (token, id) => {
  return axios.delete(`/category/delete/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
//-------------------------  GET DEVIS  ----------------------------------//
const getAllDevis = (token, searchValue, statusValue, page) => {
  return axios.get(`/devis/all/?search=${searchValue}&status=${statusValue}&page=${page}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
//-------------------------  GET SINGLE DEVIS  ----------------------------------//
const getSingleDevis = (token, id) => {
  return axios.get(`/devis/all/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

//-------------------------- CHANGE DEVIS STATUS --------------------------------//
 const quoteStatus = (token, id, data) => {
  return axios.put(`/devis/all/${id}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  })
 }

const AdminServices = {
  quoteStatus,
  getSingleDevis,
  getUserList,
  blockUser,
  getUser,
  getCategoriesList,
  newCategory,
  editCategory,
  delCategory,getAllDevis
};

export default AdminServices;
