import authReducer from "../redux/slices/AuthSlices";
import adminReducer from "../redux/slices/AdminSlices";
import productReducer from "../redux/slices/ProductSlices";

const rootReducer = {
  auth: authReducer,
  admin: adminReducer,
  products: productReducer,
};

export default rootReducer;
