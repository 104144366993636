import React, { useState } from "react";
import { Icons } from "./../../assets/icons/index";
import { useNavigate, useParams } from "react-router-dom";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { codeVerification } from "../../../api/redux/slices/AuthSlices";
import { toast } from "react-toastify";

const EnterCode = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    code: "",
  });


  const handleCodeVerif = (e) => {
    e.preventDefault();
    let data = {
      code: values.code,
    };
    dispatch(codeVerification({id: params.id, data: data}))
    .unwrap()
    .then((res) => {
      toast.success("Approuvé", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => {
        navigate(`/new_credentials/${params.id}/${values.code}`);
      }, 1000);
    })
    .catch((err) => {
      toast.error(err.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
    
  };
  return (
    <div id="enterCode">
      <div className="container--auth">
        <h1>Réinitialisé votre mot de passe</h1>
        <form className="form" onSubmit={handleCodeVerif}>
          <div className="line">
            <label>Code de verification</label>
            <input
              type="text"
              placeholder="Entrez le code"
              value={values.code}
              onChange={(e) => setValues({ ...values, code: e.target.value })}
            />
          </div>
          <div className="buttons">
            <button
              type="submit"
              className="btn--main"
            >
              ENVOYEZ
              {loading ? "..." : <Icons.BsArrowRightCircle className="icon" />}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EnterCode;
