// import React, { useState } from "react";
import "./index.scss";
import logo from "./img/logo2.svg";
// import useOnclickOutside from "react-cool-onclickoutside";
import { Link, useNavigate } from "react-router-dom";
import { Icons } from "../assets/icons";
import { useSelector } from "react-redux";

function Navbar({ menuOpen, setMenuOpen }) {
  //--------------------- redux feature --------------------------------//
  const { authenticated } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  

  // const [showLanguageChoice, setShowLanguageChoice] = useState(false);
  // const ref = useOnclickOutside(() => {
  //   setShowLanguageChoice(false);
  // });
  const authStatus = () => {
    authenticated ? navigate("/profile") : navigate("/login");
  };

  return (
    <div className={"navbarMain " + (menuOpen && "active")}>
      <nav className="nav1">
        <div className="left">
          <p>
            <Icons.BsTelephone className="icon" />
            <span>( +216 ) 22 753 335</span>
          </p>
          <p>
            <Icons.FiMail className="icon" />
            <span>sotuef@yahoo.fr</span>
          </p>
          <p style={{cursor:"pointer"}} onClick={()=> navigate('/contact')}>
            <Icons.BiMap className="icon" />
            <span>Route cité Erriadh, Zaouiet Sousse, 4081 Tunisia</span>
          </p>
        </div>
        <div className="right">
          <p>Bienvenue chez SOTEF, Equipements de forage</p>
          <p style={{ cursor: "pointer" }} onClick={authStatus}>
            <Icons.BiUser className="icon" />
            { authenticated ? <span style={{fontSize:"1rem"}}>{user?.name}</span>: "Compte"}
          </p>
          {/* <div
            onClick={() => setShowLanguageChoice(!showLanguageChoice)}
            className="lang"
            style={{ cursor: "pointer" }}
          >
            <span>Langue</span>
            <Icons.BsChevronDown className="icon" />
            {showLanguageChoice && (
              <ul id="language--list" ref={ref}>
                <li onClick={() => setShowLanguageChoice(false)}>Français</li>
                <li onClick={() => setShowLanguageChoice(false)}>English</li>
              </ul>
            )}
          </div> */}
        </div>
      </nav>
      <nav className="nav2">
        <div className="logo" onClick={() => navigate("/")}>
          <img src={logo} alt="logo" />
        </div>
        <div id="end">
          <ul className="nav_items">
            <li className="nav_link">
              <Link to="/">Acceuil</Link>
            </li>
            <li className="nav_link">
              <Link to="/">A propos de nous</Link>
            </li>
            <li className="nav_link">
              <Link to="/products/1">Produits</Link>
            </li>
            <li id="cart_link">
              <Link to={authenticated ? "/cart": "/login"}>Panier</Link>
              <Icons.FiShoppingCart style={{ fontSize: "1.3rem" }} />
              <div className="cart">
                <span>{user?.cart?.length ? user?.cart?.length : '0'}</span>
              </div>
            </li>
            <li className="nav_link">
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </div>
        <div id="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
          {menuOpen ? (
            <Icons.IoMdClose
              style={{
                fontSize: "2rem",
                color: "#DB1E1E",
                marginRight: "1rem",
              }}
            />
          ) : (
            <Icons.BiMenu
              style={{
                fontSize: "2rem",
                color: "#DB1E1E",
                marginRight: "1rem",
              }}
            />
          )}
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
