import React, { Fragment, useEffect, useState } from "react";
import logo from "../../../assets/images/logo2.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Icons } from "../../../assets/icons";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { logout } from "../../../../api/redux/slices/AuthSlices";

const SideBar = () => {
  const { pathname } = useLocation();
  const currentPage = pathname.split("/")[1];
  const [pageSelected, setPageSelected] = useState(currentPage);
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const handleLogout = () => {
    dispatch(logout())
      .unwrap()
      .then(() => {
        toast.success("Deconnecté avec succès", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate('/')
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        toast.warn("Problème de deconnexion, veuillez récharger la page", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  useEffect(() => {
    setPageSelected(currentPage);
  }, [currentPage]);

  return (
    <div className="sidebar">
      <div style={{ position: "absolute" }} />
      <div className="content">
        <div id="logoBox">
          <img className="logo" src={logo} alt="sotef_logo" />
        </div>
        <ul id="nav">
          <Fragment>
            <Link to={`/users/${1}`}>
              <li
                className={
                  pageSelected === "users" ||
                  pageSelected === undefined ||
                  pageSelected === ""
                    ? "navItem active"
                    : "navItem"
                }
                onClick={() => setPageSelected("users")}
              >
                <Icons.FaUsers />
                <span>Liste des utilisateurs</span>
              </li>
            </Link>
            <Link to={`/devis/1`}>
              <li
                className={
                  pageSelected === "devis" ? "navItem active" : "navItem"
                }
                onClick={() => setPageSelected("devis")}
              >
                <Icons.BsListCheck />
                <span>Liste des devis</span>
              </li>
            </Link>
            <Link to={`/categories`}>
              <li
                className={
                  pageSelected === "categories" ? "navItem active" : "navItem"
                }
                onClick={() => setPageSelected("categories")}
              >
                <Icons.FiArchive />
                <span>Catégories</span>
              </li>
            </Link>
            <Link to={`/products/1`}>
              <li
                className={
                  pageSelected === "products" ? "navItem active" : "navItem"
                }
                onClick={() => setPageSelected("products")}
              >
                <Icons.FiShoppingBag />
                <span>Liste des produits</span>
              </li>
            </Link>
            <Link to={`/product`}>
              <li
                className={
                  pageSelected === "product" ? "navItem active" : "navItem"
                }
                onClick={() => setPageSelected("product")}
              >
                <Icons.BsPlusCircle />
                <span>Ajouter un produit</span>
              </li>
            </Link>
          </Fragment>
          <li className="logout" onClick={handleLogout}>
            <Icons.BiLogOut />
            <span>Se deconnecter</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
