import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Icons } from "./../../assets/icons/index";
import "./index.scss";
import { login } from "./../../../api/redux/slices/AuthSlices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.auth);
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();

  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  //--------------------- Login handler ---------------------------//
  const loginHandler = (e) => {
    e.preventDefault();
    
    let data = { email: values.email, password: values.password };
    dispatch(
      login({
        data: data,
      })
    )
      .unwrap()
      .then((res) => {
        setValues({ email: "", password: "" });
        localStorage.setItem("firstLogin", "true");
        toast.success("Authentifié", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate("/");
      })
      .catch((err) => {
        toast.error(err.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  //--------------------- See password feature ---------------------------//
  const seePassword = () => {
    let x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
      setShowPassword(true);
    } else {
      x.type = "password";
      setShowPassword(false);
    }
  };

  //--------------------- Login handler ---------------------------//
  const navigateToRegister = (e) => {
    e.preventDefault()
    navigate("/register");
  };

  return (
    <div id="login">
      <div className="container--auth">
        <h1>Connexion</h1>
        <form className="form" onSubmit={loginHandler}>
          <div className="line">
            <label>Email</label>
            <input
              type="email"
              placeholder="Entrez votre email"
              value={values.email}
              onChange={(e) => setValues({ ...values, email: e.target.value })}
              required
            />
          </div>
          <div className="line">
            <label>Mot de passe</label>
            <input
              type="password"
              id="password"
              placeholder="Entrez votre mot de passe"
              value={values.password}
              onChange={(e) =>
                setValues({ ...values, password: e.target.value })
              }
              required
            />
            {!showPassword ? (
              <Icons.BsEye className="eye" onClick={seePassword} />
            ) : (
              <Icons.BsEyeSlash className="eye" onClick={seePassword} />
            )}
          </div>
          <Link to="/enter_email">
            <span className="forgot-password">Mot de passe oublié ?</span>
          </Link>
          <div className="buttons">
            <button type="submit" className="btn--main">
              Connexion
              {loading ? "..." : <Icons.BsArrowRightCircle className="icon" />}
            </button>
            <span>ou</span>
            <button onClick={navigateToRegister} className="btn--secondary">
              Créer un compte <Icons.BsArrowRightCircle className="icon" />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
