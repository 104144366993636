import React from "react";
import "./index.scss";

const ProductItemQuote = (props) => {
  const { name, reference, images, quantity, uniteVente } = props.data;
  return (
    <div className="product_container">
      <div className="product_img" style={{ cursor: "default" }}>
        <img src={images.secure_url} alt="product_image" />
      </div>
      <div className="descrip_main">
        <h3 style={{textAlign:'center', paddingBottom:".5rem"}}>{name} - <span style={{textTransform:'lowercase'}}>{quantity} {uniteVente}(s)</span></h3>
        <p>{reference}</p>
      </div>
    </div>
  );
};

export default ProductItemQuote;
