import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ProductServices } from "../../services";

const initialState = {
  products: [],
  loading: false,
  product: {},
  error: null,
  errorMessage: null,
  isSuccess: false,
  imageData: {},
};

// ---------------------------------PRODUCTS FEATURES -----------------------------------------------//

export const newQuote = createAsyncThunk('/new_quote', async({token, data}, thunkApi) => {
  try {
    const res = await ProductServices.sendQuote(token, data);
    return res.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error.response.data);
  }
})
export const productsList = createAsyncThunk(
  "/products",
  async ({ searchValue, categoryValue, page }, thunkApi) => {
    try {
      const res = await ProductServices.getProductList(
        searchValue,
        categoryValue,
        page
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

export const singleProduct = createAsyncThunk(
  "/product",
  async ({ id }, thunkApi) => {
    try {
      const res = await ProductServices.getOneProduct(id);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

export const updateProduct = createAsyncThunk(
  "/update_product",
  async ({ token, id, data }, thunkApi) => {
    try {
      const res = await ProductServices.modifyProduct(token, id, data);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);
export const deleteProduct = createAsyncThunk(
  "/delete_product",
  async ({ token, id }, thunkApi) => {
    try {
      const res = await ProductServices.delProduct(token, id);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);
export const addProduct = createAsyncThunk(
  "/delete_product",
  async ({ token, data }, thunkApi) => {
    try {
      const res = await ProductServices.newProduct(token, data);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);
export const newImage = createAsyncThunk(
  "/upload_image",
  async ({ token, data }, thunkApi) => {
    try {
      const res = await ProductServices.uploadImage(token, data);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);
export const deleteImage = createAsyncThunk(
  "/delete_image",
  async ({ token, data }, thunkApi) => {
    try {
      const res = await ProductServices.destroyImage(token, data);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

export const addToCart = createAsyncThunk(
  "/add_cart",
  async ({ token, data }, thunkApi) => {
    try {
      const res = await ProductServices.addCart(token, data);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);


// --------------PRODUCTS SLICE -------------------------//
const productSlice = createSlice({
  name: "products",
  initialState,
  extraReducers: {
    [newQuote.fulfilled]: (state) => {
      state.loading = false;
    },
    [addToCart.fulfilled]: (state) => {
      state.loading = false;
    },
    [newImage.fulfilled]: (state, action) => {
      state.imageData = action.payload;
      state.loading = false;
    },
    [deleteImage.fulfilled]: (state, action) => {
      state.imageData = action.payload;
    },
    [productsList.pending]: (state) => {
      state.loading = true;
    },
    [productsList.fulfilled]: (state, action) => {
      state.products = action.payload.list;
      state.loading = false;
      state.isSuccess = true;
    },
    [productsList.rejected]: (state, { payload }) => {
      state.error = true;
      state.errorMessage = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [singleProduct.pending]: (state) => {
      state.loading = true;
    },
    [singleProduct.fulfilled]: (state, action) => {
      state.product = action.payload;
      state.loading = false;
      state.isSuccess = true;
    },
    [singleProduct.rejected]: (state, { payload }) => {
      state.error = true;
      state.errorMessage = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [addProduct.pending]: (state) => {
      state.loading = true;
    },
    [addProduct.fulfilled]: (state, action) => {
      state.loading = false;
      state.isSuccess = true;
    },
    [addProduct.rejected]: (state, { payload }) => {
      state.error = true;
      state.errorMessage = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [deleteProduct.pending]: (state) => {
      state.loading = true;
    },
    [deleteProduct.fulfilled]: (state, action) => {
      state.loading = false;
      state.isSuccess = true;
    },
    [deleteProduct.rejected]: (state, { payload }) => {
      state.error = true;
      state.errorMessage = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [updateProduct.fulfilled]: (state, action) => {
      state.loading = false;
      state.isSuccess = true;
    },
  },
});

export default productSlice.reducer;
