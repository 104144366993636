import React, { Fragment, useCallback, useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  addCategory,
  deleteCategory,
  getCategories,
  updateCategory,
} from "../../../../api/redux/slices/AdminSlices";
import { Icons } from "../../../assets/icons";
import "./index.scss";

const CategoriesListPage = () => {
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.admin);
  const { loading } = useSelector((state) => state.admin);
  const token = localStorage.getItem("accesstoken");

  const [category, setCategory] = useState("");
  const [onEdit, setOnEdit] = useState(false);
  const [catId, setCatId] = useState("");

  //--------------------------------DELETE CATEGORY ---------------------------------//
  const handleDelete = (id) => {
    dispatch(deleteCategory({ token: token, id: id }))
    .then(() => {
      dispatch(getCategories());
      toast.success("Catégorie supprimé", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setOnEdit(false);
      setCategory("");
    })
    .catch((err) => {
      toast.error(err.msg, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
  };
  //------------------------------ CATEGORY MUTATION --------------------------------------//
  const editCategory = async (id, name) => {
    setCatId(id);
    setCategory(name);
    setOnEdit(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (onEdit) {
      const data = {
        name: category,
      };
      dispatch(updateCategory({ token: token, id: catId, data: data }))
        .unwrap()
        .then(() => {
          dispatch(getCategories());
          toast.success("Catégorie modifié", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setOnEdit(false);
          setCategory("");
        })
        .catch((err) => {
          toast.error(err.msg, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } else {
      const data = {
        name: category,
      };
      dispatch(addCategory({ token: token, data: data }))
        .unwrap()
        .then(() => {
          dispatch(getCategories());
          toast.success("Catégorie ajouté", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setOnEdit(false);
          setCategory("");
        })
        .catch((err) => {
          toast.error(err.msg, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };

  //-------------------------------- GET CATEGORIES LIST ---------------------------------------//
  const stableDispatch = useCallback(() => {
    dispatch(getCategories());
  }, [dispatch]);

  useEffect(() => {
    stableDispatch();
    if (category.length === 0) {
      setOnEdit(false);
    }
  }, [stableDispatch, category]);

  if (loading && categories.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "20%",
        }}
      >
        <ThreeDots
          ariaLabel="blocks-loading"
          visible={true}
          color="#DB1E1E"
          id="loader-spinner"
        />
      </div>
    );
  }
  return (
    <div className="adminPage">
      <div className="container_categories_page">
        <div className="header">
          <h3>Nom de Catégorie</h3>
          <form onSubmit={handleSubmit} className="add_category">
            <div className="input_box">
              <input
                type="text"
                placeholder="entrer le nom d'une catégorie"
                name="category"
                value={category}
                required
                onChange={(e) => setCategory(e.target.value)}
                autoComplete="off"
              />
            </div>
            <button type="submit">
              {onEdit ? (
                <Icons.BsCheck2Square className="update_category_icon" />
              ) : (
                <Icons.BsPlusCircle className="add_category_icon" />
              )}
            </button>
          </form>
        </div>
        <div className="categories_box">
          {categories.map((cat) => (
            <Fragment key={cat._id}>
              <div className="category_box">
                <span>{cat.name}</span>
                <div className="category_actions">
                  <Icons.FiEdit
                    className="category_edit"
                    onClick={() => editCategory(cat._id, cat.name)}
                  />
                  <Icons.FaRegTrashAlt
                    className="category_trash"
                    onClick={() => handleDelete(cat._id)}
                  />
                </div>
              </div>
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CategoriesListPage;
