import React from "react";
import { Icons } from "./../../assets/icons/index";

const NotFound = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "5rem auto",
        flexDirection: "column",
        gap: "4rem",
      }}
    >
      <h1>Not Found </h1>
      <Icons.RiErrorWarningLine style={{ fontSize: "3rem" }} />
    </div>
  );
};

export default NotFound;
