import React, { useEffect, useState, useCallback, Fragment } from "react";
import TextField from "../TextField/TextField";
import "./Products.scss";
import { productsList } from "../../api/redux/slices/ProductSlices";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import clsx from "clsx";

import ProductItem from "../Home/components/ProductItem";
import { Icons } from "../assets/icons";
import { getCategories } from "../../api/redux/slices/AdminSlices";

const Products = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.products);
  const { categories } = useSelector((state) => state.admin);
  const [searchInput, setSearchInput] = useState("");
  const [categoryInput, setCategoryInput] = useState("");
  const [page, setPage] = useState(Number(params.page));

    //-------------------------------- GET CATEGORIES LIST ---------------------------------------//
    const stableCategoriesDispatch = useCallback(() => {
      dispatch(getCategories());
    }, [dispatch]);
    useEffect(() => {
      stableCategoriesDispatch();
    }, [stableCategoriesDispatch, params]);

  const stableDispatch = useCallback(() => {
    dispatch(
      productsList({
        searchValue: searchInput,
        categoryValue: categoryInput,
        page: page,
      })
    )
  }, [dispatch, searchInput, categoryInput, page]);

  useEffect(() => {
    stableDispatch();
  }, [stableDispatch]);
  
  return (
    <>
      <div className="products_list_container">
        <div className="search_container">
          <TextField
            className="input_textField"
            type={"text"}
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            placeholder="Nom du Produit"
            endAdornment={<IoSearchOutline />}
          />
          <div className={clsx("input_textField", "menu_contain")}>
            <select
              className={"sub_menu"}
              id="service"
              value={categoryInput}
              onChange={(e) => setCategoryInput(e.target.value)}
              name={"service"}
              placeholder={"Catégories"}
            >
              <option value="">Catégories</option>
              {categories.map((category) => (
                <Fragment key={category._id}>
                  <option value={category.name}>{category.name}</option>
                </Fragment>
              ))}
            </select>
          </div>
        </div>
        <div className="products_container">
          {products.map((product, index) => (
            <ProductItem key={index} data={product} />
          ))}
        </div>
        <div className="pagination">
          <div className="count">
            Page <span>{page > 0 && page}</span>
          </div>
          <div className="arrows">
            <Link
              to={page === 1 ? `/products/${page}` : `/products/${page - 1}`}
            >
              <Icons.BiChevronLeft
                className="arrow"
                onClick={() => {
                  page === 1 ? setPage(1) : setPage(page - 1);
                }}
              />
            </Link>
            <Link to={`/products/${page + 1}`}>
              <Icons.BiChevronRight
                className="arrow"
                onClick={() => {
                  page === 0 ? setPage(1) : setPage(page + 1);
                }}
              />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
