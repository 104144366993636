import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AdminServices } from "../../services";

const initialState = {
  users: [],
  user: {},
  categories: [],
  quotes: [],
  quote: [],
  loading: false,
  error: null,
  errorMessage: null,
};

//-------------------------------------------------ADMIN FEATURES ----------------------------------------//

export const usersList = createAsyncThunk(
  "/get_users",
  async ({ token, searchValue, blocked, page }, thunkApi) => {
    try {
      const res = await AdminServices.getUserList(
        token,
        searchValue,
        blocked,
        page
      );
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

export const changeUserStatus = createAsyncThunk(
  "/block",
  async ({ token, id, data }, thunkApi) => {
    try {
      const res = await AdminServices.blockUser(token, id, data);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

export const getUserById = createAsyncThunk(
  "/single_user",
  async ({ token, id, }, thunkApi) => {
    try {
      const res = await AdminServices.getUser(token, id);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

export const getCategories = createAsyncThunk(
  "/categories",
  async (thunkApi) => {
    try {
      const res = await AdminServices.getCategoriesList();
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

export const addCategory = createAsyncThunk(
  "/add_categorie",
  async ({token, data},thunkApi) => {
    try {
      const res = await AdminServices.newCategory(token, data);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);
export const updateCategory = createAsyncThunk(
  "/update_categorie",
  async ({token,id, data},thunkApi) => {
    try {
      const res = await AdminServices.editCategory(token, id, data);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);
export const deleteCategory = createAsyncThunk(
  "/delete_categorie",
  async ({token,id},thunkApi) => {
    try {
      const res = await AdminServices.delCategory(token, id);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);
export const devisList = createAsyncThunk('/all_quote', async({token, searchValue, statusValue, page},thunkApi) => {
  try {
    const res = await AdminServices.getAllDevis(token, searchValue, statusValue, page);
    return res.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error.response.data);
  }
});
export const singleDevis = createAsyncThunk('/single_quote', async({token, id},thunkApi) => {
  try {
    const res = await AdminServices.getSingleDevis(token, id);
    return res.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error.response.data);
  }
})
export const quoteAction = createAsyncThunk('/quote_action', async({token, id, data},thunkApi) => {
  try {
    const res = await AdminServices.quoteStatus(token, id, data);
    return res.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error.response.data);
  }
})

//-------------------------------------------------ADMIN SLICE ------------------------------------------//

const adminSlice = createSlice({
  name: "admin",
  initialState,
  extraReducers: {
    [quoteAction.fulfilled]: (state) => {
      state.loading = false;
      state.error = false;
    },
    [singleDevis.fulfilled]: (state, action) => {
      state.quote = action.payload.devis;
      state.loading = false;
      state.error = false;
    },
    [devisList.fulfilled]: (state, action) => {
      state.quotes = action.payload.list;
      state.loading = false;
      state.error = false;
    },
    [usersList.pending]: (state) => {
      state.loading = true;
    },
    [usersList.fulfilled]: (state, action) => {
      state.users = action.payload.list;
      state.loading = false;
      state.error = false;
    },
    [usersList.rejected]: (state, { payload }) => {
      state.error = true;
      state.errorMessage = payload;
      state.loading = false;
    },
    [changeUserStatus.pending]: (state) => {
      state.loading = true;
    },
    [changeUserStatus.fulfilled]: (state, action) => {
      state.error = false;
      state.loading = false;
    },
    [changeUserStatus.rejected]: (state, { payload }) => {
      state.error = true;
      state.loading = false;
    },
    [getUserById.pending]: (state) => {
      state.loading = true;
    },
    [getUserById.fulfilled]: (state, action) => {
      state.user = action.payload.user
      state.error = false;
      state.loading = false;
    },
    [getUserById.rejected]: (state, { payload }) => {
      state.error = true;
      state.loading = false;
    },
    [getCategories.pending]: (state) => {
      state.loading = true;
    },
    [getCategories.fulfilled]: (state, action) => {
      state.categories = action.payload.categories;
      state.loading = false;
      state.error = false;
    },
    [getCategories.rejected]: (state, { payload }) => {
      state.error = true;
      state.errorMessage = payload;
      state.loading = false;
    },
    [addCategory.pending]: (state) => {
      state.loading = true;
    },
    [addCategory.fulfilled]: (state, action) => {
      state.error = false;
      state.loading = false;
    },
    [addCategory.rejected]: (state, { payload }) => {
      state.error = true;
      state.errorMessage = payload;
      state.loading = false;
    },
    [updateCategory.pending]: (state) => {
      state.loading = true;
    },
    [updateCategory.fulfilled]: (state, action) => {
      state.error = false;
      state.loading = false;
    },
    [updateCategory.rejected]: (state, { payload }) => {
      state.error = true;
      state.errorMessage = payload;
      state.loading = false;
    },
    [deleteCategory.pending]: (state) => {
      state.loading = true;
    },
    [deleteCategory.fulfilled]: (state, action) => {
      state.error = false;
      state.loading = false;
    },
    [deleteCategory.rejected]: (state, { payload }) => {
      state.error = true;
      state.errorMessage = payload;
      state.loading = false;
    },
  },
});

export default adminSlice.reducer;
