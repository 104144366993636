import React, { Fragment, useRef, useState } from "react";
import "./index.scss";
import { Icons } from "./../../../assets/icons/index";
import { useSelector } from "react-redux";
import { countryList } from "./countryList";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";

const ContactPage = () => {
  const { loading } = useSelector((state) => state.auth);

  const [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    message: "",
    country: "",
    subject: "",
  });
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_7dvtq4",
        "template_sv42v49",
        form.current,
        "user_LO7cWR4Q5PlQMbPIaHShw"
      )
      .then(
        () => {
          toast.success("message envoyé", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setValues({
            name: "",
            email: "",
            phone: "",
            address: "",
            message: "",
            country: "",
            subject: "",
          });
        },
        (error) => {
          toast.success(error.text, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      );
  };

  return (
    <div id="contact">
      <div className="main">
        <div className="main__left">
          <div className="header_left">
            <h1>Contactez-nous</h1>
            <Icons.FiMail />
          </div>
          <form className="form" ref={form} onSubmit={sendEmail}>
            <div className="line">
              <label>Nom complet</label>
              <input
                type="text"
                placeholder="nom complet"
                name="user_name"
                value={values.name}
                onChange={(e) => setValues({ ...values, name: e.target.value })}
              />
            </div>
            <div className="line">
              <div className="line-boxes">
                <div className="line--box">
                  <label>Email</label>
                  <input
                    type="email"
                    required
                    name="user_email"
                    placeholder="email"
                    value={values.email}
                    onChange={(e) =>
                      setValues({ ...values, email: e.target.value })
                    }
                  />
                </div>
                <div className="line--box">
                  <label>Pays</label>
                  <select
                    value={values.country}
                    name="country"
                    onChange={(e) =>
                      setValues({ ...values, country: e.target.value })
                    }
                  >
                    <option value="">--choisir--</option>
                    {countryList.map((country, index) => (
                      <Fragment key={index}>
                        <option value={country.name}>{country.name}</option>
                      </Fragment>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="line">
              <div className="line-boxes">
                <div className="line--box">
                  <label>Addresse</label>
                  <input
                    type="address"
                    required
                    name="address"
                    placeholder="addresse"
                    value={values.address}
                    onChange={(e) =>
                      setValues({ ...values, address: e.target.value })
                    }
                  />
                </div>
                <div className="line--box">
                  <label>Téléphone</label>
                  <input
                    type="tel"
                    placeholder="téléphone"
                    name="phone"
                    value={values.phone}
                    onChange={(e) =>
                      setValues({ ...values, phone: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="line">
              <label>Objet</label>
              <input
                type="text"
                placeholder="objet de votre message"
                required
                name="subject"
                value={values.subject}
                onChange={(e) =>
                  setValues({ ...values, subject: e.target.value })
                }
              />
            </div>
            <div className="line">
              <label>Message</label>
              <textarea
                name="message"
                cols="30"
                rows="5"
                placeholder="message"
                value={values.message}
                onChange={(e) =>
                  setValues({ ...values, message: e.target.value })
                }
              ></textarea>
            </div>
            <div className="buttons">
              <button className="btn--main" type="submit" value="Send">
                Soumettre
                {loading ? (
                  "..."
                ) : (
                  <Icons.BsArrowRightCircle className="icon" />
                )}
              </button>
            </div>
          </form>
        </div>
        <div className="main__right">
          <div className="main__right_1">
            <p>
              Société Tunisienne <br /> d'Equipements de Forage <br /> (SOTEF)
            </p>
          </div>
          <div className="main__right_2">
            <p>
              Commerce d'equipements de <br /> forage :
            </p>
            <ul>
              <li>Tricones, Betonites, Pompes...</li>
              <li>
                Tubes, crépines en PVC fileté, en acier <br /> Noir et en acier
                inoxydables
              </li>
            </ul>
          </div>
          <div className="main__right_3">
            <ul>
              <li>
                <Icons.BsTelephone className="icon_contact" />
                <span>Téléphone: (+216) 22 753 335</span>
              </li>
              <li>
                <Icons.FaFax className="icon_contact" />
                <span>Fax: (+216) 73 238 033</span>
              </li>
              <li>
                <Icons.BsPhone className="icon_contact" />
                <span>GSM1: (+216) 22 753 335</span>
              </li>
              <li>
                <Icons.BsPhone className="icon_contact" />
                <span>GSM2: (+216) 52 753 335</span>
              </li>
              <li>
                <Icons.FiMail className="icon_contact" />
                <span>Email: sotuef@yahoo.fr</span>
              </li>
              <li>
                <Icons.BiMap className="icon_contact" />
                <span>Route cité Erriadh, Zaouiet Sousse, Tunisia</span>
              </li>
              <li>
                <Icons.GiMailbox className="icon_contact" />
                <span>Boite postale: (+216) 22 753 335</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="map">
        <iframe
          title="sotuef_direction"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d47410.03049961905!2d10.646901185869115!3d35.78123621860305!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd8aaca242100b%3A0x328428ce26fffca2!2sSousse%20Riadh!5e0!3m2!1sfr!2stn!4v1668608470038!5m2!1sfr!2stn"
          style={{ width: "100%", height: "300px" }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactPage;
