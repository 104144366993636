import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import {
  EnterCode,
  EnterEmail,
  Login,
  NewCredentials,
  Register,
} from "../auth";
import Home from "../Home/Home";
import Products from "../Products/Products";
import ShoppingCart from "../ShoppingCart/ShoppingCart";
import ViewProduct from "../ViewProduct/ViewProduct";
import NotFound from "./notFound";
import ContactPage from "./user/contactPage";
import UserProfile from "./user/profile";
import UpdateProfile from "./user/profile/UpdateProfile";

const Layout = () => {
  const { authenticated } = useSelector((state) => state.auth);
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/login" element={<Login />} />
      {authenticated && (
        <Fragment>
          <Route exact path="/profile" element={<UserProfile />} />
          <Route exact path="/profile/update" element={<UpdateProfile />} />
        </Fragment>
      )}
      <Route exact path="/register" element={<Register />} />
      <Route exact path="/enter_email" element={<EnterEmail />} />
      <Route exact path="/enter_code/:id" element={<EnterCode />} />
      <Route
        exact
        path="/new_credentials/:id/:code"
        element={<NewCredentials />}
      />
      <Route exact path="/products/:page/:id" element={<ViewProduct />} />
      <Route exact path="/products/:page" element={<Products />} />
      <Route exact path="/products/:page/:id" element={<ViewProduct />} />
      <Route exact path="/cart" element={<ShoppingCart />} />
      <Route exact path="/contact" element={<ContactPage />} />
      <Route exact path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Layout;
