import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AuthServices } from "../../services";

const initialState = {
  loading: false,
  user: [],
  error: null,
  role: "",
  actived: "",
  message:"",
  authenticated:false
};

export const updateProfile =createAsyncThunk('/update_profile',  async({token, data}, thunkApi) => {
  try {
    const res = await AuthServices.modifyProfile(token, data);
    return res.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error.response.data);
  }
})
// ------------------------- REFRESH TOKEN ----------------------------------------------//
export const refreshAccessToken = createAsyncThunk('/refreshToken',  async({token}, thunkApi) => {
  try {
    const res =  await AuthServices.refreshToken(token);    
    return res.data;
    
  } catch (error) {
    return thunkApi.rejectWithValue(error.response.data);
  }
}); 
// ------------------------- LOGOUT ----------------------------------------------//
export const logout = createAsyncThunk('/logout',  async() => {
  const res =  await AuthServices.signout();
  localStorage.removeItem('accesstoken');
  localStorage.removeItem('firstLogin');
		return res.data;
}); 
// ------------------------- GET USER ----------------------------------------------//
export const getUser = createAsyncThunk('/profile', async ({ token }, thunkApi) => {
	try {
		const res = await AuthServices.getUserByToken(token);
		return res.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

//---------------------------- login ----------------------//
export const login = createAsyncThunk("/login", async ({ data }, thunkApi) => {
  try {
    const res = await AuthServices.connexion(data);
    localStorage.setItem("accesstoken", res.data.accesstoken);
    return res.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error.response.data);
  }
});
//---------------------------- create a new user ----------------------//
export const register = createAsyncThunk(
  "/register",
  async ({ data }, thunkApi) => {
    try {
      const res = await AuthServices.register(data);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);
//---------------------------- verify user email ----------------------//
export const emailVerification = createAsyncThunk(
  "/email",
  async ({ data }, thunkApi) => {
    try {
      const res = await AuthServices.verifEmail(data);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);
//---------------------------- verify send verification code to user verified----------------------//
export const sendCode = createAsyncThunk(
  "/code",
  async ({ data }, thunkApi) => {
    try {
      const res = await AuthServices.sendVerificationCode(data);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);
//------------------------- verify code sent to the client ----------------------------//
export const codeVerification = createAsyncThunk(
  "/code",
  async ({ id, data }, thunkApi) => {
    try {
      const res = await AuthServices.verifCode(id,data);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);
//------------------------- new password----------------------------//
export const newPassword = createAsyncThunk(
  "/code",
  async ({data }, thunkApi) => {
    try {
      const res = await AuthServices.newCredentials(data);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [updateProfile.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [logout.pending]: (state) => {
			state.loading = true;
		},
		[logout.fulfilled]: (state, action) => {
			state.loading = false;
			state.authenticated = false;
		},
		[logout.rejected]: (state, action) => {
			state.error = true;
		},
    [refreshAccessToken.pending]: (state) => {
			state.loading = true;
		},
		[refreshAccessToken.fulfilled]: (state, action) => {
			state.loading = false;
		},
		[refreshAccessToken.rejected]: (state, action) => {
			state.error = true;
		},
    [getUser.pending]: (state) => {
			state.loading = true;
		},
		[getUser.fulfilled]: (state, action) => {
			state.user = action.payload.user;
			state.authenticated = true;
			state.role = action.payload.user.role;
			state.actived = action.payload.user.blocked;
			state.loading = false;
		},
		[getUser.rejected]: (state, { payload }) => {
			state.error = true;
			state.message = payload.msg;
			state.loading = false;
		},
    [login.pending]: (state) => {
      state.loading = true;
    },
    [login.fulfilled]: (state, action) => {
      state.user = action.payload;
      state.loading = false;
    },
    [login.rejected]: (state) => {
      state.error = true;
      state.loading = false;
    },
    [register.pending]: (state) => {
      state.loading = true;
    },
    [register.fulfilled]: (state, action) => {
      state.user = action.payload;
      state.loading = false;
    },
    [register.rejected]: (state) => {
      state.error = true;
      state.loading = false;
    },
    [emailVerification.pending]: (state) => {
      state.loading = true;
    },
    [emailVerification.fulfilled]: (state, action) => {
      state.user = action.payload;
      state.loading = false;
    },
    [emailVerification.rejected]: (state) => {
      state.error = true;
      state.loading = false;
    },
    [sendCode.pending]: (state) => {
      state.loading = true;
    },
    [sendCode.fulfilled]: (state, action) => {
      state.message = action.payload;
      state.loading = false;
    },
    [sendCode.rejected]: (state) => {
      state.error = true;
      state.loading = false;
    },
    [newPassword.pending]: (state) => {
      state.loading = true;
    },
    [newPassword.fulfilled]: (state, action) => {
      state.message = action.payload;
      state.loading = false;
    },
    [newPassword.rejected]: (state) => {
      state.error = true;
      state.loading = false;
    },

  },
});

export default authSlice.reducer;
