import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { logout } from "../../../../api/redux/slices/AuthSlices";
import "./index.scss";

const UserProfile = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  //----------------------LOGOUT FEATURE ----------------------------//
  const handleLogout = () => {
    if (window.confirm("Souhaitez vous vraiment vous deconnectez ? ")) {
      dispatch(logout())
        .unwrap()
        .then(() => {
          toast.success("Deconnecté avec succès", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          navigate('/')
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          toast.warn("Problème de deconnexion, veuillez récharger la page", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };
  return (
    <div className="adminPage">
      <h1 style={{ fontSize: "2rem" }}>Profil</h1>
      <div className="content_user_details">
        <div className="line">
          <h3>Nom</h3>
          <span style={{textTransform:"capitalize"}}>{user?.name}</span>
        </div>
        <div className="line">
          <h3>Société</h3>
          <span>{user?.company}</span>
        </div>
        <div className="line">
          <h3>Adresse email</h3>
          <span>{user?.email}</span>
        </div>
        <div className="line">
          <h3>Numéro de téléphone</h3>
          <span>{user?.phone}</span>
        </div>
        <div className="line">
          <h3>Adresse</h3>
          <span>{user?.address}</span>
        </div>
        <div className="line">
          <h3>Statut</h3>
          <span
            className={user?.blocked === "false" ? "statut" : "statut bloqué"}
          >
            {user?.blocked === "false" ? "Actif" : "Bloqué"}
          </span>
        </div>
      </div>
      <div className="buttons">
        <Link to='/profile/update'><button className="btn--main">Mofifier Profile</button></Link>
        <button className="btn--secondary" onClick={handleLogout}>
          Se deconnecter
        </button>
      </div>
    </div>
  );
};

export default UserProfile;
