import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { productsList } from "../../../api/redux/slices/ProductSlices";
import "./MainComponent.scss";
import { Icon } from "@iconify/react";
import ProductItem from "./ProductItem";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const MainComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.products);
  const [searchInput] = useState("");
  const [categoryInput] = useState("");

  const stableDispatch = useCallback(() => {
    dispatch(
      productsList({
        searchValue: searchInput,
        categoryValue: categoryInput,
        page: 1,
      })
    )
      .unwrap()
      .then((res) => {
      })
      .catch((err) => {
        toast.error(err.msg, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }, [dispatch, searchInput, categoryInput]);

  useEffect(() => {
    stableDispatch();
  }, [stableDispatch]);

  const datas = [
    {
      id: 1,
      icon: "/icons/icon-1.svg",
      title: "EQUIPE TOUJOURS A DISPOSITION",
      content:
        "Le département administratif-commercial est composé par un groupe jeune et dynamique qui grâce à sa disponibilité et courtoisie soutien et suit le client pendant les phases avant et après vente.",
    },
    {
      id: 2,
      icon: "/icons/icon-2.svg",
      title: "SOLUTIONS PERSONNALISEES",
      content:
        "Le département technique travaille tous les jours à la recherche de nouvelles solutions afin d’offrir des réponses concrètes au grand nombre de problèmes différents de tout chantier.",
    },
    {
      id: 3,
      icon: "/icons/icon-3.svg",
      title: "PRODUITS FIABLES",
      content:
        "Tous nos produits sont certifiés et réalisés avec des matériaux de haut de gamme afin de garantir la plus haute résistance au collapse à la traction et aux contraintes mécaniques.",
    },
  ];

  return (
    <div className="container">
      <div className="wrapper">
        <div className="wrapper_first">
          <img src="/images/soleil.png" alt="image_soleil" />
        </div>
        <div className="wrapper_second">
          <h1>A PROPOS DE NOUS </h1>
          
          <p>
            La socièté Tunisienne d'équipements de forages SOTEF fournit depuis 2010 des tubes, des filtres et des accessoires pour la construction des forages d'eaux. 
          </p>

          <p>
          Grâce en particulier à la qualité et à l’innovation, l’entreprise s’est développée de manière constante et continue, et repose aujourd’hui, avec ses 7.000 m2 de surface, sur une structure solide et compétitive.
          </p>

          <p>
           Dans le but d’un partenariat toujours plus étroit et efficace, SOTEF est aux côtés de son client de la phase de l’offre jusqu’à l’étape finale de la livraison du matériel. Chaque jour, la section technique travaille à la recherche de nouvelles solutions pour fournir des réponses concrètes aux différentes problématiques présentées par chaque chantier.
          </p>
          <p>
          La section administrative et commerciale, est dotée d’une équipe jeune, dynamique et très expérimentée, disponible et à l’écoute de la clientèle, qu’elle assiste avant et après la vente.
          </p>
        
        </div>
      </div>
      <div className="team">
        {datas.map((data) => (
          <div className="team_item" key={data.id}>
            <div className="team_item_icon">
              <img src={data.icon} alt="icon" />
            </div>
            <div className="team_item_title">
              <h3>{data.title}</h3>
            </div>
            <div className="team_item_content">
              <p>{data.content}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="product">
        <div className="product_header">
          <h1>
            Produits et catégories
            <Icon
              icon="ic:outline-chevron-right"
              color="#db1e1e"
              inline={true}
            />
          </h1>
          <div className="products_container">
            {products.slice(0, 8).map((product, index) => (
              <ProductItem key={index} data={product} />
            ))}
          </div>
          <div className="btn" onClick={() => {window.scrollTo({ top: 0, behavior: 'smooth' });navigate("/products/1")}}>
            <button>Voir Plus</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainComponent;
