import React, { Fragment, useCallback, useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getCategories } from "../../../../../api/redux/slices/AdminSlices";
import { addProduct, newImage, productsList } from "../../../../../api/redux/slices/ProductSlices";
import { Icons } from "../../../../assets/icons";

const AddProductPage = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const token = localStorage.getItem("accesstoken");
  const { categories } = useSelector((state) => state.admin);
  const navigate = useNavigate()

  const [values, setValues] = useState({
    name: "",
    category: "",
    reference: "",
    description: "",
    assemblage: "",
    stock: "",
    marque: "",
    uniteVente: ""
  });
  const [imageUp, setImageUp] = useState(false);
  const [fileUploaded, setFileUploaded] = useState("");
  const [image, setImage] = useState("");


   //-------------------------------- HANDLE UPLOAD A NEW PRODUCT ---------------------------------------//
   const handleNewProduct = (e) => {
    e.preventDefault();
    const data = {
      name: values.name,
      category: values.category,
      reference: values.reference,
      description: values.description,
      assemblage: values.assemblage,
      stock: values.stock,
      uniteVente: values.uniteVente,
      marque: values.marque, 
      images: image
    };
    dispatch(addProduct({token:token, data: data}))
    .unwrap()
    .then(()=> {
      dispatch(
        productsList({
          searchValue: "",
          categoryValue: "",
          page: 1,
        })
      )
      navigate(`/products/1`)
      toast.info("Produit Ajouté", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
    .catch((err) => {
      toast.error(err.msg, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
   }
  //-------------------------------- GET CATEGORIES LIST ---------------------------------------//
  const stableCategoriesDispatch = useCallback(() => {
    dispatch(getCategories());
  }, [dispatch]);
  useEffect(() => {
      stableCategoriesDispatch();
  }, [stableCategoriesDispatch, params]);

  // ----------------------------------------- UPLOAD IMAGE-----------------------------------------//
  const handleUpload = (e) => {
    setImageUp(true);
    setFileUploaded(URL.createObjectURL(e.target.files[0]));
    const file = e.target.files[0];
    let formData = new FormData();
    formData.append("file", file);
    dispatch(newImage({ token: token, data: formData }))
      .unwrap()
      .then((res) => {
        setImage(res)
        setFileUploaded(res.secure_url);
        toast.info("Image téléchargé", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        toast.info(err.msg, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  //------------------------------ OPEN FOR IMAGE INPUT ------------------------------//
  const open_file = () => {
    setImageUp(false);
  };
  const handleCancel= (e) => {
    e.preventDefault();
    navigate(`/products/1`);
  }
console.log(imageUp)
  return (
    <div className="adminPage">
      <Link to={`/products/${params.page}`}>
        <h4>
          <Icons.BiChevronLeft />
          Retour
        </h4>
      </Link>
      <h1 style={{ fontSize: "1.5rem" }}>Ajouter un produit</h1>
      <div className="update_product">
        <div className="update_image">
          <div className="imgUpload">
            {!imageUp ? (
              <input
                type="file"
                id="imgUploading"
                accept=".png, .svg, .jpg"
                onChange={handleUpload}
                title="clickez pour ajouter une image"
              />
            ) : (
              <div className="img_box">
                <img src={`${fileUploaded}`} alt="product_image" />
              </div>
            )}
          </div>
          <div className="btn_update">
            {imageUp &&(
              <button className="btn--main" onClick={open_file}>
                Modifier la photo
              </button>
            )}
          </div>
        </div>
        <div className="data_box">
          <form className="form" onSubmit={handleNewProduct}>
            <div className="line">
              <label>Nom du produit</label>
              <input
                type="text"
                value={values.name}
                placeholder="nom du produit"
                onChange={(e) => setValues({ ...values, name: e.target.value })}
                required
              />
            </div>
            <div className="line">
              <label>Réference</label>
              <input
                type="text"
                placeholder="ex: Ref.20393A"
                value={values.reference}
                onChange={(e) =>
                  setValues({ ...values, reference: e.target.value })
                }
                required
              />
            </div>
            <div className="line">
              <label>Marque</label>
              <input
                type="text"
                value={values.marque}
                placeholder="marque"
                onChange={(e) =>
                  setValues({ ...values, marque: e.target.value })
                }
                required
              />
            </div>
            <div className="line">
              <label>Catégorie</label>
              <div className="line">
                <select
                  value={values.category}
                  onChange={(e) =>
                    setValues({ ...values, category: e.target.value })
                  }
                >
                  <option value="">Tout</option>
                  {categories.map((value) => (
                    <Fragment key={value._id}>
                      <option value={value.name}>{value.name}</option>
                    </Fragment>
                  ))}
                </select>
              </div>
            </div>
            <div className="line">
              <label>Stock</label>
              <input
                type="number"
                placeholder="stock disponible"
                value={values.stock}
                onChange={(e) =>
                  setValues({ ...values, stock: e.target.value })
                }
                required
              />
            </div>
            <div className="line">
              <label>Unité</label>
              <input
                type="text"
                placeholder="ex: metre"
                value={values.uniteVente}
                onChange={(e) =>
                  setValues({ ...values, uniteVente: e.target.value })
                }
                required
              />
            </div>
            <div className="line">
              <label>Description</label>
              <textarea
                type="text"
                rows={5}
                placeholder="description"
                value={values.description}
                onChange={(e) =>
                  setValues({ ...values, description: e.target.value })
                }
                required
              />
            </div>
            <div className="line">
              <label>Assemblage</label>
              <textarea
                type="text"
                rows={6}
                placeholder="assemblage"
                value={values.assemblage}
                onChange={(e) =>
                  setValues({ ...values, assemblage: e.target.value })
                }
                required
              />
            </div>
            <div className="btns">
              <button className="btn--main" type="submit">
                Confirmer
              </button>
              <button
              type="button"
                className="btn--secondary"
                onClick={handleCancel}
              >
                Annuler
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddProductPage;
