import React from 'react';
import {Link} from "react-router-dom"
import './index.scss';
import useOnclickOutside from "react-cool-onclickoutside";
import { useSelector } from 'react-redux';

const Menu = ({menuOpen, setMenuOpen}) => {

    const { authenticated } = useSelector((state) => state.auth);
  const ref = useOnclickOutside(() => {
    setMenuOpen(false);
  });
  return (
         <div className={"menu " + (menuOpen && "active")} ref={ref}>
            <ul>
                <li onClick={()=>setMenuOpen(!menuOpen)}>
                    <Link to="/">
                        Acceuil
                    </Link>
                </li>
                <li onClick={()=>setMenuOpen(!menuOpen)}>
                    <Link to="/">
                        A Propos de nous
                    </Link>
                </li>
                <li onClick={()=>setMenuOpen(!menuOpen)}>
                    <Link to="/products/1">
                        Produits
                    </Link>
                </li>
                <li onClick={()=>setMenuOpen(!menuOpen)}>
                    <Link to={authenticated ? "/cart": "/login"}>
                        Panier
                    </Link>
                </li>
                <li onClick={()=>setMenuOpen(!menuOpen)}>
                    <Link to="/contact">
                        Contact
                    </Link>
                </li>

            </ul>
        </div>
  )
}

export default Menu