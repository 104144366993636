import React, { useEffect, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  addToCart,
  productsList,
  singleProduct,
} from "../../api/redux/slices/ProductSlices";
import { useDispatch, useSelector } from "react-redux";
import "./ViewProduct.scss";
import { Icons } from "../assets/icons";
import ProductItem from "../Home/components/ProductItem";
import { toast } from "react-toastify";
import { getUser } from "../../api/redux/slices/AuthSlices";

const ViewProduct = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const token = localStorage.getItem("accesstoken");
  const { products, product } = useSelector((state) => state.products);
  const params = useParams();
  const { authenticated } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.auth);

  const [panier, setPanier] = useState(user?.cart);

  const stableProductListDispatch = useCallback(() => {
    dispatch(
      productsList({
        searchValue: "",
        categoryValue: product?.list?.category,
        page: 1,
      })
    )
      .unwrap()
      .then((res) => {})
      .catch((err) => {
        toast.error(err.msg, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }, [dispatch, product]);

  const stableOneDispatch = useCallback(() => {
    dispatch(singleProduct({ id: params.id }));
  }, [dispatch, params.id]);

  useEffect(() => {
    stableProductListDispatch();
  }, [ stableProductListDispatch]);

  //----------------- one product side effect -------------------//
  useEffect(() => {
    stableOneDispatch();
  }, [stableOneDispatch]);


  const handleAddToCart = (e) => {
    
    e.preventDefault()

    if (authenticated) {
      const check = panier.every((item) => {
        return item._id !== product.list._id;
      });
      
      if(check){
        setPanier([...panier, { ...product.list, quantity: 1 }]);
        const data = { cart: [...user?.cart, { ...product.list, quantity: 1 }] };
      dispatch(addToCart({ token: token, data: data }))
        .unwrap()
        .then(() => {
          toast.success("Produit Ajouté au panier", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          dispatch(getUser({ token: token }));
        })
        .catch((err) => {
          toast.error(err.msg, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      } else{
        toast.info("Produit dejà ajouté au panier", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      
      
    } else {
      navigate('/login')
    }
  };

  return (
    <div className="container_view_product">
      <div className="view_product">
        <div className="view_product_img">
          <img src={product?.list?.images.secure_url} alt="product" />
        </div>
        <div className="view_product_details">
          <h1>
            {product?.list?.name}
            <span>
              {product?.list?.stock <= 0 ? "(stock épuisé)" : "(en stock)"}
            </span>
          </h1>
          <h3>{product?.list?.reference}</h3>
          <h3>
            Marque: <span>{product?.list?.marque}</span>
          </h3>
          <p>{product?.list?.description}</p>
          <h2>ASSEMBLAGE</h2>
          <div className="descript">{product?.list?.assemblage}</div>
          <div className="add_panier">
            <button
              onClick={(e) => {
                window.scrollTo({ top: 0, behavior: "smooth" });
                handleAddToCart(e);
              }}
            >
              AJOUTEZ AU PANIER
              <Icons.FiShoppingCart
                style={{
                  fontSize: "1.3rem",
                  color: "#FFF",
                  marginLeft: "10px",
                }}
              />
            </button>
          </div>
        </div>
      </div>
      <div className="view_product_category">
        <p className="category">Produits dans la même catégorie</p>
        <div className="products_container">
          {products.map((product, index) => (
            <ProductItem key={index} data={product} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ViewProduct;
