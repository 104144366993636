import React, { useState } from "react";
import "./Cart.scss";
import TextField from "../../TextField/TextField";
import { Icons } from "./../../assets/icons/index";

const Cart = ({ product, increment, deleteItem, setOnChangeQty, onChangeQty }) => {
  const [itemQty, setItemQty] = useState(product.quantity);
  const [ indice, setIndice ] = useState(null);

  return (
    <div className="cart_container">
      <div className="cart_img">
        <img src={product?.images?.secure_url} alt="product" />
      </div>
      <div className="cart_description">
        <h2>{product.name}</h2>
        <div className="ref">{product.reference}</div>

        <div className="qte">{`Quantité de ${product.uniteVente}`}:</div>
        <TextField
          className="input_num"
          type="number"
          min="1"
          value={itemQty}
          onChange={(e) => {
            setIndice(product._id)
            setOnChangeQty(true);
            setItemQty(e.target.value);
          }}
        />
        {indice === product._id && onChangeQty && (
          <button
            className="btn--main"
            onClick={(e) => increment(e, product._id, itemQty)}
            style={{
              fontSize: ".6rem",
              fontFamily: "Poppins",
              height: "2rem",
              borderRadius: "0",
              width: "150px",
              marginTop: "1rem",
            }}
          >
            valider la quantité
          </button>
        )}
      </div>
      <div className="cart_delete">
        <Icons.FaRegTrashAlt
          style={{color: "#DB1E1E", cursor: "pointer" }}
          onClick={() => deleteItem(product._id)}
          className='trash_one_cart'
        />
      </div>
    </div>
  );
};

export default Cart;
