import {
  BsArrowRightCircle,
  BsEye,
  BsEyeSlash,
  BsTelephone,BsCheck2Square,BsCheck2Circle,
  BsChevronDown,BsListCheck, BsPlusCircle, BsThreeDotsVertical, BsPhone
} from "react-icons/bs";
import { BiUser, BiMap, BiMenu, BiLogOut, BiChevronRight, BiChevronLeft } from "react-icons/bi";
import { FiEdit, FiMail, FiShoppingCart, FiArchive, FiDownload, FiShoppingBag, FiSettings, FiAlertOctagon } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { FaUsers,FaFax, FaRegTrashAlt, FaMailBulk } from "react-icons/fa";
import { RiArrowDropDownLine, RiArrowDropUpLine, RiErrorWarningLine } from "react-icons/ri";
import { GiMailbox } from "react-icons/gi";

export const Icons = {
  BsArrowRightCircle,
  BsEye,
  BsEyeSlash,
  BiUser,
  FiMail,
  BiMap,FaFax,BsPhone,GiMailbox,
  BsTelephone,FaMailBulk,BsCheck2Circle,
  BsChevronDown,FiEdit,FaRegTrashAlt,BsCheck2Square,
  FiShoppingCart,BiLogOut,FiAlertOctagon,RiErrorWarningLine,
  BiMenu,FiArchive,FiShoppingBag,BsPlusCircle,FiSettings,FiDownload,
  IoMdClose, FaUsers, BsListCheck, BiChevronRight, BiChevronLeft, BsThreeDotsVertical,RiArrowDropDownLine, RiArrowDropUpLine
};
