import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  quoteAction,
  singleDevis,
} from "../../../../api/redux/slices/AdminSlices";
import { Icons } from "../../../assets/icons";
import "./index.scss";
import ProductItemQuote from "./ProductItemQuote";
import { CSVLink } from "react-csv";

const DevisDetails = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const token = localStorage.getItem("accesstoken");
  const { quote } = useSelector((state) => state.admin);

  //----------------------------------QUOTE ACTION ----------------------------------//
  const handleQuoteAction = (e) => {
    e.preventDefault();
    let data;
    if (quote?.status === "envoyé") {
      data = {
        status: "non envoyé",
      };
    } else {
      data = { status: "envoyé" };
    }
    dispatch(quoteAction({ token: token, id: params.id, data: data }))
      .unwrap()
      .then((res) => {
        toast.success(`marqué comme ${data.status}`, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dispatch(
          singleDevis({
            token: token,
            id: params.id,
          })
        );
      })
      .catch((err) => {
        toast.error(err.msg, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  //-------------------------------- GET QUOTE LIST ---------------------------------------//
  const stableDispatch = useCallback(() => {
    dispatch(
      singleDevis({
        token: token,
        id: params.id,
      })
    )
      .unwrap()
      .then(() => {})
      .catch((err) => {
        toast.warn(err.msg, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
  }, [dispatch, params.id, token]);

  useEffect(() => {
    if (token) {
      stableDispatch();
    }
  }, [stableDispatch, token]);

  //----------------------------DOWNLOAD CSV FEATURE -------------------------------------//
  const headers = [
    { label: "Nom du client", key: "name" },
    { label: "Société du client", key: "company" },
    { label: "Email du client", key: "email" },
    { label: "telephone du client", key: "phone" },
    { label: "adresse du client", key: "addresse" },
    { label: "Statut du devis", key: "statut" },
    { label: "Produits et quantité", key: "productName" },
  ];
  const csvData = [
    {
      name: quote?.userName,
      company: quote?.userCompany,
      email: quote?.userEmail,
      phone: quote?.userPhone,
      addresse: quote?.userAddress,
      statut: quote?.status,
      productName: quote?.products?.map(
        (el) => `${el.name}(${el.quantity})${el.uniteVente}`
      ),
    },
  ];

  return (
    <div className="adminPage">
      <div className="header">
        <h1>Demande de devis</h1>
        <div className="header__right">
          <button className="btn--main" onClick={handleQuoteAction}>
            {quote?.status === "envoyé" ? (
              <span>Marqué le devis comme non envoyé</span>
            ) : (
              <span>Marqué le devis comme envoyé</span>
            )}
            <Icons.BsCheck2Circle className="check_icon" />
          </button>
          <CSVLink data={csvData} target="_blank" filename={"devis.csv"} headers={headers}>
            <button className="btn--main" title="download csv">
              <Icons.FiDownload
                style={{ color: "#43D9B8", fontSize: "1.2rem" }}
              />
            </button>
          </CSVLink>
        </div>
      </div>
      <div className="user--info">
        <p>Client: {quote?.userName}</p>
        <p>Société: {quote?.userCompany}</p>
        <div className="line">
          <span>Email: {quote?.userEmail}</span>
          <span>Téléphone: {quote?.userPhone}</span>
        </div>
        <span>Adresse: {quote?.userAddress}</span>
      </div>
      <div className="product">
        <div className="products_container">
          {quote?.products?.map((product, index) => (
            <ProductItemQuote key={index} data={product} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DevisDetails;
