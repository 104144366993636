import axios from "../baseUrl";

// --------------GET USER BY HIS TOKEN -------------------------//
const getUserByToken = (token) => {
  return axios.get("/user/auth", {
    headers: { Authorization: `Bearer ${token}` },
  });
};
// -------------- REFRESH TOKEN -------------------------//
const refreshToken = (token) => {
  return axios.get("/user/refresh_token", {
    headers: { Authorization: `Bearer ${token}` },
  });
};
// -------------- LOGOUT -------------------------//
const signout = () => {
  return axios.get("/user/logout");
};
//---------------------------- Login & register-------------------//
const connexion = (data) => {
  return axios.post("/user/login", data);
};
const register = (data) => {
  return axios.post("/user/register", data);
};
//---------------------- verify email and send verification code -------------------- //
const verifEmail = (data) => {
  return axios.post("/user/email", data);
};
const sendVerificationCode = (data) => {
  return axios.post("/user/send_code", data);
};
//------------------------------------------- verify code -------------------------------//
const verifCode = (id, data) => {
  return axios.post(`/user/code/${id}`, data);
};

// ------------------------- new credentials ----------------------------//
const newCredentials = (data) => {
  return axios.put(`/user/reset_password`, data);
};
// ------------------------- new credentials ----------------------------//
const modifyProfile = (token,data) => {
  return axios.put(`/user/update_profile`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const AuthServices = {
  connexion,
  register,
  verifEmail,
  sendVerificationCode,
  verifCode,
  newCredentials,
  getUserByToken,
  refreshToken,
  signout,
  modifyProfile
};
export default AuthServices;
