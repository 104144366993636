import React, { Fragment, useCallback, useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getCategories } from "../../../../api/redux/slices/AdminSlices";
import { productsList } from "../../../../api/redux/slices/ProductSlices";
import { Icons } from "./../../../assets/icons/index";
import ProductItemAdmin from "./ProductItemAdmin";

const ProductsListPage = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.products);
  const { categories } = useSelector((state) => state.admin);

  const [searchInput, setSearchInput] = useState("");
  const [categorySelected, setCategorySelected] = useState("");
  const [page, setPage] = useState(Number(params.page));

  //-----------------------PRODUCTS LIST -----------------------//
  const stableDispatch = useCallback(() => {
    dispatch(
      productsList({
        searchValue: searchInput,
        categoryValue: categorySelected,
        page: page,
      })
    );
  }, [dispatch, searchInput, categorySelected, page]);

  useEffect(() => {
    stableDispatch();
  }, [stableDispatch]);
  //-------------------------------- GET CATEGORIES LIST ---------------------------------------//
  const stableCategoriesDispatch = useCallback(() => {
    dispatch(getCategories());
  }, [dispatch]);

  useEffect(() => {
    stableCategoriesDispatch();
  }, [stableCategoriesDispatch]);

  return (
    <div className="adminPage">
      <div className="navbar">
        <div className="left">
          <div className="nav-search">
            <input
              type="text"
              placeholder="Rechercher ..."
              value={searchInput}
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
            />
          </div>
          <div className="filters">
            <select
              value={categorySelected}
              onChange={(e) => setCategorySelected(e.target.value)}
            >
              <option value="">Tout</option>
              {categories.map((value) => (
                <Fragment key={value._id}>
                  <option value={value.name}>{value.name}</option>
                </Fragment>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="product">
        <div className="products_container">
          {products.map((product, index) => (
            <ProductItemAdmin key={index} data={product} />
          ))}
        </div>
      </div>
      <div className="pagination">
        <div className="count">
          Page <span>{page > 0 && page}</span>
        </div>
        <div className="arrows">
          <Link to={page === 1 ? `/products/${page}` : `/products/${page - 1}`}>
            <Icons.BiChevronLeft
              className="arrow"
              onClick={() => {
                page === 1 ? setPage(1) : setPage(page - 1);
              }}
            />
          </Link>
          <Link to={`/products/${page + 1}`}>
            <Icons.BiChevronRight
              className="arrow"
              onClick={() => {
                page === 0 ? setPage(1) : setPage(page + 1);
              }}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProductsListPage;
