import "./App.scss";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar";
import Layout from "./components/layout";
import { useEffect, useState } from "react";
import Menu from "./components/Navbar/menu/";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser, refreshAccessToken } from "./api/redux/slices/AuthSlices";
import MainAdminPage from "./components/layout/admin";
import { Fragment } from "react";

function App() {
  //-------------------- responsive menu feature---------------------------//
  const [menuOpen, setMenuOpen] = useState(false);
  //-------------------- navigation feature---------------------------//
  const token = localStorage.getItem("accesstoken");
  const firstLogin = localStorage.getItem("firstLogin");
  //--------------------- redux feature --------------------------------//
  const { role } = useSelector((state) => state.auth);
  const { blocked } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  //------------------- call back dispatch -------------------------//
  const stableDispatch = useCallback(() => {
    if (token) {
      dispatch(getUser({ token: token }))
        .unwrap()
        .then((res) => {})
        .catch((err) => {
          toast.warn(err, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        });
    }
  }, [dispatch, token]);

  const stableDispatchRefresToken = useCallback(() => {
    if (token && firstLogin === "true") {
      setTimeout(() => {
        dispatch(refreshAccessToken({ token: token }))
          .unwrap()
          .then((res) => {
            localStorage.setItem("accesstoken", res.accesstoken);
          })
          .catch((err) => {
            console.log(err);
          });
      }, 24 * 60 * 60 * 1000);
    }
  }, [dispatch, token, firstLogin]);

  //------------------------ hydration feature ------------------------------//
  useEffect(() => {
    if (blocked === "true") {
      toast.info("Utilisateur bloqué", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => {
        localStorage.removeItem("accesstoken");
        window.location.href = "/";
      }, 2000);
    }
    stableDispatch();
    stableDispatchRefresToken();
  }, [stableDispatch, stableDispatchRefresToken, token, role, blocked]);


  //------------------- main component ------------------------------//
  return (
    <div className="App">
      <div style={{ position: "absolute" }}>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
      {role === 1 ? (
        <MainAdminPage />
      ) : (
        <Fragment>
          <Navbar setMenuOpen={setMenuOpen} menuOpen={menuOpen} />
          <Menu setMenuOpen={setMenuOpen} menuOpen={menuOpen} />
          <div style={{ minHeight: "100vh" }}>
            <Layout />
          </div>
          <Footer />
        </Fragment>
      )}
    </div>
  );
}

export default App;
