import React from "react";
import "./index.scss";
import SideBar from "./sideBar";
import { Route, Routes } from "react-router-dom";
import UsersListPage from "./users";
import DevisListPage from "./devis";
import CategoriesListPage from "./categories";
import ProductsListPage from "./products";
import AddProductPage from "./products/addProduct";
import UserDetailsPage from "./userDetails";
import UpdateProductAdmin from "./products/update";
import NotFound from "../notFound";
import DevisDetails from "./devisDetails";

const MainAdminPage = () => {
  return (
    <div id="backOffice">
      <SideBar />
      <div id="backOfficeLayout">
        <Routes>
          <Route exact path="/" element={<UsersListPage />} />
          <Route exact path="/users/:page" element={<UsersListPage />} />
          <Route exact path="/users/:page/:id" element={<UserDetailsPage />} />
          <Route exact path="/devis/:page" element={<DevisListPage />} />
          <Route exact path="/devis/:page/:id" element={<DevisDetails />} />
          <Route exact path="/categories" element={<CategoriesListPage />} />
          <Route exact path="/products/:page" element={<ProductsListPage />} />
          <Route exact path="/product/" element={<AddProductPage />} />
          <Route exact path="/products/update/:page/:id" element={<UpdateProductAdmin />} />
          <Route exact path="*" element={<NotFound />} />
        </Routes>
      </div>
    </div>
  );
};

export default MainAdminPage;
