import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { changeUserStatus, usersList } from "../../../../api/redux/slices/AdminSlices";
import { Icons } from "../../../assets/icons";
import "./index.scss";
import useOnclickOutside from "react-cool-onclickoutside";
import { ThreeDots } from "react-loader-spinner";

const UsersListPage = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const token = localStorage.getItem("accesstoken");
  const { users } = useSelector((state) => state.admin);
  const { loading } = useSelector((state) => state.admin);

  const [searchInput, setSearchInput] = useState("");
  const [userStatus, setUserStatus] = useState("");
  const [page, setPage] = useState(Number(params.page));
  const [indiceUser, setIndiceUser] = useState(null);
  const [orderOpenDropdown, setOrderOpenDropdown] = useState(false);

  const ref = useOnclickOutside(() => {
    setOrderOpenDropdown(false);
  });
  //-------------------------------- GET USERS LIST ---------------------------------------//
  const stableDispatch = useCallback(() => {
    dispatch(
      usersList({
        token: token,
        page: page,
        searchValue: searchInput,
        blocked: userStatus,
      })
    )
  }, [dispatch, page, token, userStatus, searchInput]);

  useEffect(() => {
    if (token) {
      stableDispatch();
    }
  }, [stableDispatch, token]);
  //-------------------------------- HANDLE BLOCK USER ---------------------------------------//
  const handleBlock = (e, id, status) => {
    e.preventDefault();
    const data = status === 'false'? {
      blocked:'true'
    } : {blocked:'false'}
    dispatch(changeUserStatus({token: token,id:id, data: data}))
    .unwrap()
    .then(()=> {
      setOrderOpenDropdown(false)
      dispatch(
        usersList({
          token: token,
          page: page,
          searchValue: searchInput,
          blocked: userStatus,
        })
      )
    })
  };

  if (loading && users.length===0 ) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '20%' }}>
        <ThreeDots  ariaLabel="blocks-loading"visible={true} color="#DB1E1E" id="loader-spinner" />
      </div>
    );
}

  return (
    <div className="adminPage">
      <div className="navbar">
        <div className="left">
          <div className="nav-search">
            <input
              type="text"
              placeholder="Rechercher ..."
              value={searchInput}
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
            />
          </div>
          <div className="filters">
            <select
              value={userStatus}
              onChange={(e) => setUserStatus(e.target.value)}
            >
              <option value="">Tout</option>
              <option value="true">Bloqué</option>
              <option value="false">Actif</option>
            </select>
          </div>
        </div>
      </div>
      <div className="mainContent">
        <table className="content">
          <thead>
            <tr className="titres">
              <td>Utilisateurs</td>
              <td>Société</td>
              <td>Statut</td>
              <td>Action</td>
            </tr>
          </thead>
          <tbody>
            {users?.filter(el=>el.role!==1)?.map((user, index) => (
              <tr className="row" key={index}>
                <td style={{ textTransform: "uppercase" }}>{user.name}</td>
                <td>{user.company}</td>
                <td
                  style={
                    user.blocked === "true"
                      ? { color: "#DB1E1E" }
                      : { color: "#19180A" }
                  }
                >
                  {user.blocked === "true" ? "Bloqué" : "Actif"}
                </td>
                <td className="last_row_element">
                  <Link to={`/users/${page}/${user._id}`} className="link-order">
                    <Icons.BsEye className="see-order" />
                  </Link>
                  <div className="contain">
                    <Icons.FiSettings
                      onClick={() => {
                        setOrderOpenDropdown(!orderOpenDropdown);
                        setIndiceUser(user._id);
                      }}
                      className="order-option"
                    />
                    {orderOpenDropdown && indiceUser === user._id && (
                      <div className="modalSelectOpt" ref={ref}>
                        <ul>
                          <li onClick={(e) => handleBlock(e,user._id, user.blocked)}>
                            {user.blocked ==='false' ? "Bloqué l'utilisateur" : "Debloqué utilisateur"}
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <div className="count">
          Page <span>{page > 0 && page}</span>
        </div>
        <div className="arrows">
          <Link to={page === 1 ? `/users/${page}` : `/users/${page - 1}`}>
            <Icons.BiChevronLeft
              className="arrow"
              onClick={() => {
                page === 1 ? setPage(1) : setPage(page - 1);
              }}
            />
          </Link>
          <Link to={`/users/${page + 1}`}>
            <Icons.BiChevronRight
              className="arrow"
              onClick={() => {
                page === 0 ? setPage(1) : setPage(page + 1);
              }}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UsersListPage;
