import React, { useState } from "react";
import "./ProductItem.scss";
import { Icon } from "@iconify/react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../../api/redux/slices/ProductSlices";
import { toast } from "react-toastify";
import { getUser } from "../../../api/redux/slices/AuthSlices";

const ProductItem = (props) => {
  const productData = props.data;
  const { _id, name, reference, images } = props.data;

  const { authenticated } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const token = localStorage.getItem("accesstoken");

  const [panier, setPanier] = useState(user?.cart);

  const handleAddToCart = () => {
    if (authenticated) {
      const check = panier.every((item) => {
        return item._id !== _id;
      });

      if(check){
        setPanier([...panier, { ...productData, quantity: 1 }]);
        const data = { cart: [...user?.cart, { ...productData, quantity: 1 }] };
      dispatch(addToCart({ token: token, data: data }))
        .unwrap()
        .then(() => {
          toast.success("Produit ajouté au panier", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          dispatch(getUser({ token: token }));
        })
        .catch((err) => {
          toast.error(err.msg, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      } else{
        toast.info("Produit dejà ajouté au panier", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
     navigate('/login')
    }
  };

  return (
    <div className="product_container">
      <div
        onClick={() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
          navigate(`/products/${params.page?params.page : 1}/${_id}`);
        }}
        className="product_img"
      >
        <img src={images.secure_url} alt="product_image" />
      </div>
      <div className="descrip_main">
        <h3>{name}</h3>
        <p>{reference}</p>
      </div>
      <div className="container_icon">
        <Icon
          icon="ion:eye-outline"
          width="24"
          height="24"
          style={{ marginRight: "20px", marginBottom: "14px" }}
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            navigate(`/products/${params.page?params.page : 1}/${_id}`);
          }}
        />
        <Icon
          icon="ant-design:shopping-cart-outlined"
          width="24"
          height="24"
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            handleAddToCart();
          }}
        />
      </div>
    </div>
  );
};

export default ProductItem;
