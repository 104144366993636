import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./index.scss";
import { Icons } from "./../../assets/icons/index";
import { useDispatch, useSelector } from "react-redux";
import { newPassword } from "../../../api/redux/slices/AuthSlices";
import { toast } from "react-toastify";

const NewCredentials = () => {
  const { loading } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const [values, setValues] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleNewCredentials = (e) => {
    e.preventDefault();
    let data = {
      code: params.code,
      user_id: params.id,
      password: values.password,
      confirmPassword: values.confirmPassword,
    };
    dispatch(
      newPassword({
        data: data
      })
    )
      .unwrap()
      .then(() => {
        toast.success("Mot de passe changé avec succès", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          navigate(`/`);
        }, 1000);
      })
      .catch((err) => {
        console.log(err)
        toast.error(err.message || err, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  return (
    <div id="enterCrendentials">
      <div className="container--auth">
        <h1>Confirmation de mot de passe</h1>
        <form className="form" onSubmit={handleNewCredentials}>
          <div className="line">
            <label>Mot de passe</label>
            <input
              type="password"
              placeholder="Entrez votre mot de passe"
              value={values.password}
              onChange={(e) =>
                setValues({ ...values, password: e.target.value })
              }
            />
          </div>
          <div className="line">
            <label>Confirmer le mot de passe</label>
            <input
              type="password"
              placeholder="Confirmer votre mot de passe"
              value={values.confirmPassword}
              onChange={(e) =>
                setValues({ ...values, confirmPassword: e.target.value })
              }
            />
          </div>
          <div className="buttons">
            <button type="submit" className="btn--main">
              Confirmer
              {loading ? "..." : <Icons.BsArrowRightCircle className="icon" />}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewCredentials;
