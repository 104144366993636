import React from 'react'
import "./Home.scss";
import ImageSlider from '../ImageSlider/ImageSlider'
import MainComponent from './components/MainComponent';

const Home = () => {
  const slides = [
    {
      url: '/images/slider-1.png',
      title: 'Slide 1',
    },
    {
      url: '/images/slider-2.png',
      title: 'Slide 2',
    },
    {
      url: '/images/slider-3.png',
      title: 'Slide 3',
    },
    {
      url: '/images/slider-4.png',
      title: 'Slide 4',
    },
  ]
  return (
    <div className='home_container'>
      <div className='container_slides'>
        <ImageSlider slides={slides} />
      </div>
      <div className='main'>
        <MainComponent />
      </div>
    </div>
  )
}

export default Home