import axios from "axios";
const token = localStorage.getItem('accesstoken');

export default axios.create({
  // baseURL: "localhost:5000/api",
  baseURL: "https://sotuef-api.onrender.com/api",
  // baseURL: "https://sotef-api.onrender.com/api",
  headers: {
    // "Content-type": "application/json",
    Accept: 'application/json',
    Authorization: `Bearer ${token}`
  }
});