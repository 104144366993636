import axios from "../baseUrl";

// -----------------------------GET ALL PRODUCTS -------------------------//
const getProductList = (searchValue, categoryValue, page) => {
  return axios.get(
    `/product/all/?search=${searchValue}&category=${categoryValue}&page=${page}`
  );
};
// -----------------------------GET ONE PRODUCT -------------------------//
const getOneProduct = (id) => {
  return axios.get(`/product/all/${id}`);
};
//----------------------------------UPDATE PRODUCT------------------------//
const modifyProduct = (token, id, data) => {
  return axios.put(`/product/update/${id}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
//----------------------------------UPDATE PRODUCT------------------------//
const delProduct = (token, id) => {
  return axios.delete(`/product/delete/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
//----------------------------------UPDATE PRODUCT------------------------//
const newProduct = (token, data) => {
  return axios.post(`/product/add/`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
//-----------------------------------UPLOAD IMAGE ------------------------//
const uploadImage = (token, data) => {
  return axios.post(`/image/add/`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
};
//-----------------------------------DELETE IMAGE ------------------------//
const destroyImage = (token, data) => {
  return axios.post(`/image/delete/`, data, {
    headers: {
      // "content-type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
};

//----------------------------------ADD PRODUCT TO CART ---------------------------//
const addCart = (token, data) => {
  return axios.patch(`/user/addcart`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

//----------------------------SEND QUOTE------------------------------------------------//
const sendQuote = (token, data) => {
  return axios.post(`/devis/add`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const ProductServices = {
  getProductList,
  getOneProduct,
  newProduct,
  delProduct,
  modifyProduct,
  uploadImage,
  destroyImage,
  addCart,
  sendQuote
};

export default ProductServices;
