import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { changeUserStatus, getUserById } from "./../../../../api/redux/slices/AdminSlices";
import "./index.scss";
import { useSelector } from "react-redux";
import { Icons } from "../../../assets/icons";
import { ThreeDots } from "react-loader-spinner";

const UserDetailsPage = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const token = localStorage.getItem("accesstoken");

  const { user } = useSelector((state) => state.admin);
  const { loading } = useSelector((state) => state.admin);

  //-------------------------------- GET USER LIST ---------------------------------------//
  const stableDispatch = useCallback(() => {
    dispatch(
      getUserById({
        token: token,
        id: params.id,
      })
    )
      .unwrap()
      .catch((err) => {
        toast.warn(err.msg, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
  }, [dispatch, params.id, token]);

  useEffect(() => {
    if (token) {
      stableDispatch();
    }
  }, [stableDispatch, token]);


  if (loading && Object.keys(user).length===0) {
			return (
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '20%' }}>
					<ThreeDots  ariaLabel="blocks-loading"visible={true} color="#DB1E1E" id="loader-spinner" />
				</div>
			);
	}

  //--------------------------------------- BLOCK USER -------------------------------------------//
  const handleBlock = (e) => {
    e.preventDefault();
    const data = user.blocked === 'false'? {
      blocked:'true'
    } : {blocked:'false'}
    dispatch(changeUserStatus({token: token,id:user._id, data: data}))
    .unwrap()
    .then(()=> {
      toast.success("Réussi", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(
      getUserById({
        token: token,
        id: params.id,
      })
    )
    })
  };
  return (
    <div className="adminPage">
      <h1 style={{ fontSize: "2rem" }}>Profil</h1>
      <div className="content_user_details">
        <div className="line">
          <h3>Nom</h3>
          <span>{user?.name}</span>
        </div>
        <div className="line">
          <h3>Société</h3>
          <span>{user?.company}</span>
        </div>
        <div className="line">
          <h3>Adresse email</h3>
          <span>{user?.email}</span>
        </div>
        <div className="line">
          <h3>Numéro de téléphone</h3>
          <span>{user?.phone}</span>
        </div>
        <div className="line">
          <h3>Adresse</h3>
          <span>{user?.address}</span>
        </div>
        <div className="line">
          <h3>Statut</h3>
          <span
            className={user?.blocked === "false" ? "statut" : "statut bloqué"}
          >
            {user?.blocked === "false" ? "Actif" : "Bloqué"}
          </span>
        </div>
      </div>
      <div className="btn_block">
        <button onClick={handleBlock}>
          <span>{user.blocked==="false"?"Bloqué":"Debloqué"} cet utilisateur</span>
          <Icons.FiAlertOctagon className="alert_icon"/>
        </button>
      </div>
    </div>
  );
};

export default UserDetailsPage;
