import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { register } from "../../../api/redux/slices/AuthSlices";
import { Icons } from "./../../assets/icons/index";
import "./index.scss";

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.auth);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [values, setValues] = useState({
    name: "",
    company: "",
    email: "",
    password: "",
    confirmPassword: "",
    address: "",
    phone: "",
  });

  //--------------------- Login handler ---------------------------//
  const registerHandler = (e) => {
    e.preventDefault();
    let data = {
      name: values.name,
      company: values.company,
      email: values.email,
      password: values.password,
      confirmPassword: values.confirmPassword,
      phone: values.phone,
      address: values.address,
    };
    dispatch(register({ data: data }))
      .unwrap()
      .then(() => {
        toast.success("compte créer", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate("/login");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  //--------------------- See password feature ---------------------------//
  const seePassword = () => {
    let x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
      setShowPassword(true);
    } else {
      x.type = "password";
      setShowPassword(false);
    }
  };
  
  const seeConfirmPassword = () => {
    let x = document.getElementById("confirmPassword");
    if (x.type === "password") {
      x.type = "text";
      setShowConfirmPassword(true);
    } else {
      x.type = "password";
      setShowConfirmPassword(false);
    }
  };

  //--------------------- Login handler ---------------------------//
  const navigateToLogin = (e) => {
    e.preventDefault()
    navigate("/login");
  };

  return (
    <div id="register">
      <div className="container--auth">
        <h1>Créer un compte</h1>
        <form className="form" onSubmit={registerHandler}>
          <div className="line">
            <label>Nom complet</label>
            <input
              type="text"
              placeholder="Entrez votre nom complèt"
              value={values.name}
              onChange={(e) => setValues({ ...values, name: e.target.value })}
              required
            />
          </div>
          <div className="line">
            <div className="line-boxes">
              <div className="line--box">
                <label>Email</label>
                <input
                  type="email"
                  placeholder="Entrez votre email"
                  value={values.email}
                  onChange={(e) =>
                    setValues({ ...values, email: e.target.value })
                  }
                  required
                />
              </div>
              <div className="line--box">
                <label>Téléphone</label>
                <input
                  type="number"
                  placeholder="Ex: (+000) 00 000 000"
                  value={values.phone}
                  onChange={(e) =>
                    setValues({ ...values, phone: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <div className="line">
            <label>Société</label>
            <input
              type="text"
              placeholder="Entrez le nom de votre société"
              value={values.company}
              onChange={(e) =>
                setValues({ ...values, company: e.target.value })
              }
              required
            />
          </div>
          <div className="line">
            <label>Addresse</label>
            <input
              type="address"
              placeholder="Entrez votre addresse"
              value={values.address}
              onChange={(e) =>
                setValues({ ...values, address: e.target.value })
              }
              required
            />
          </div>
          <div className="line">
            <label>Mot de passe</label>
            <input
              type="password"
              id="password"
              placeholder="Entrez votre mot de passe"
              value={values.password}
              onChange={(e) =>
                setValues({ ...values, password: e.target.value })
              }
              required
            />
            {!showPassword ? (
              <Icons.BsEye className="eye" onClick={seePassword} />
            ) : (
              <Icons.BsEyeSlash className="eye" onClick={seePassword} />
            )}
          </div>
          <div className="line">
            <label>Répétez votre mot de passe</label>
            <input
              type="password"
              id="confirmPassword"
              placeholder="Entrez votre mot de passe"
              value={values.confirmPassword}
              onChange={(e) =>
                setValues({ ...values, confirmPassword: e.target.value })
              }
              required
            />
            {!showConfirmPassword ? (
              <Icons.BsEye className="eye" onClick={seeConfirmPassword} />
            ) : (
              <Icons.BsEyeSlash className="eye" onClick={seeConfirmPassword} />
            )}
          </div>
          <div className="buttons">
            <button className="btn--main" type="submit">
              Créer un compte
              {loading ? "..." : <Icons.BsArrowRightCircle className="icon" />}
            </button>
            <span>ou</span>

            <button
              type="submit"
              onClick={navigateToLogin}
              className="btn--secondary"
            >
              Connexion
              <Icons.BsArrowRightCircle className="icon" />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
