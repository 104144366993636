import React from "react";
import "./Footer.scss";
import logo from "../Navbar/img/logo2.svg";
import {useNavigate } from "react-router-dom";

function Footer() {
  const navigate=useNavigate()
  return (
    <footer id="footer">
      <div className="container">
        <div className="footer--col">
          <img src={logo} alt="logo" />
        </div>
        <div className="footer--col">
          <h2>Navigation</h2>
          <ul>
            <li onClick={()=>{window.scrollTo({ top: 0, behavior: 'smooth' });navigate('/')}}>Acceuil</li>
            <li onClick={()=>{window.scrollTo({ top: 0, behavior: 'smooth' });navigate('/products/1')}}>Nos Produits</li>
            <li onClick={() => {window.scrollTo({ top: 0, behavior: 'smooth' });navigate('/contact')}}>Nous contacter</li>
            <li onClick={()=>{window.scrollTo({ top: 0, behavior: 'smooth' });navigate('/')}}>A propos de nous</li>
          </ul>
        </div>
        <div className="footer--col">
          <h2>Mon compte</h2>
          <ul>
            <li onClick={()=>{window.scrollTo({ top: 0, behavior: 'smooth' });navigate('/register')}}>Creer un compte</li>
            <li onClick={()=>{window.scrollTo({ top: 0, behavior: 'smooth' });navigate('/login')}}>Se connecter</li>
          </ul>
        </div>
        <div className="footer--col">
          <h2>Horaires D'ouverture</h2>
          <ul>
            <li>Lundi 08:00 - 16:00</li>
            <li>Mardi 08:00 - 16:00</li>
            <li>Mercredi 08:00 - 16:00</li>
            <li>Jeudi 08:00 - 16:00</li>
            <li>Vendredi 08:00 - 16:00</li>
          </ul>
        </div>
      </div>
      <div className="licence">
        <span className="span1">
          Copyright © 2022 SOTEF | Conception et réalisation
        </span>
        <a href="https://roniceyemeli.netlify.app/" target="_blank" rel="noreferrer"><span className="span2">Ronice Yemeli</span></a>
      </div>
    </footer>
  );
}

export default Footer;
