import React from "react";
import styles from "./TextField.scss";
import clsx from "clsx";

const TextField = (props) => {
    const {
        className,
        label,
        error = false,
        isTextArea = false,
        endAdornment,
        startAdornment,
        onChange,
        ...rest
    } = props;


    return (
        <div className={clsx(styles.root, className)}>
            {
                startAdornment &&
                <div className={styles.start_adornment}>
                    {startAdornment}
                </div>
            }
            <div>
                {label && <div className="caption">{label}</div>}
                {
                    isTextArea
                        ?
                        <textarea
                            style={{
                                paddingRight: endAdornment ? 34 : 16,
                                paddingLeft: startAdornment ? 34 : 16,
                            }}
                            className={clsx((error && styles.input_error))}
                            onChange={onChange}
                            cols={200} {...rest}></textarea>
                        :
                        <input
                            style={{
                                paddingRight: endAdornment ? 34 : 16,
                                paddingLeft: startAdornment ? 34 : 16,
                                fontSize:"1rem"
                            }}
                            onChange={onChange}
                            {...rest}
                            className={clsx((error && styles.input_error))}
                        />
                }

            </div>

            {endAdornment
                && <div style={{ display: "flex" }} className={styles.end_adornment}>
                    {endAdornment}
                </div>
            }
        </div>
    )
}


export default TextField
