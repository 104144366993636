import React, { useState } from "react";
import { Icons } from "./../../assets/icons/index";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  emailVerification,
  sendCode,
} from "../../../api/redux/slices/AuthSlices";
import { toast } from "react-toastify";

const EnterEmail = () => {
  const { loading } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    email: "",
  });
  const handleEmailVerification = (e) => {
    e.preventDefault();
    let data = {
      email: values.email,
    };
    dispatch(emailVerification({ data: data }))
      .unwrap()
      .then((res) => {
        console.log(res);
        let userId = res.user._id;
        toast.success("code envoyé à votre email", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dispatch(sendCode({ data: data }))
          .unwrap()
          .then((res) => {
            console.log(res);
            navigate(`/enter_code/${userId}`);
          })
          .catch((err) => {
            console.log(err);
            toast.error(err.message, {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      })
      .catch((err) => {
        toast.error(err.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  return (
    <div id="enterEmail">
      <div className="container--auth">
        <h1>Réinitialisé votre mot de passe</h1>
        <form className="form" onSubmit={handleEmailVerification}>
          <div className="line">
            <label>Entrez votre addresse émail</label>
            <input
              type="email"
              placeholder="Entrez votre email"
              value={values.email}
              onChange={(e) => setValues({ ...values, email: e.target.value })}
            />
          </div>
          <div className="buttons">
            <button type="submit" className="btn--main">
              ENVOYEZ
              {loading ? "..." : <Icons.BsArrowRightCircle className="icon" />}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EnterEmail;
