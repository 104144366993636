import React, { useEffect } from "react";
import { useState } from "react";
import { Icons } from "../../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getUser,
  updateProfile,
} from "./../../../../api/redux/slices/AuthSlices";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const UpdateProfile = () => {
  const { loading } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("accesstoken");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [values, setValues] = useState({
    name: user.name,
    company: user.company,
    email: user.email,
    password: "",
    confirmPassword: "",
    address: user.address,
    phone: user.phone,
  });
  //--------------------- See password feature ---------------------------//
  const seePassword = () => {
    let x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
      setShowPassword(true);
    } else {
      x.type = "password";
      setShowPassword(false);
    }
  };
  const seeConfirmPassword = () => {
    let x = document.getElementById("confirmPassword");
    if (x.type === "password") {
      x.type = "text";
      setShowConfirmPassword(true);
    } else {
      x.type = "password";
      setShowConfirmPassword(false);
    }
  };

  //-----------------------------UPDATE PROFILE HANDLER ----------------------------//
  const handleUpdate = (e) => {
    e.preventDefault();
    let data = {
      name: values.name,
      email: values.email,
      address: values.address,
      phone: values.phone,
      company: values.company,
    };
    if (values.password.length !== 0 || values.confirmPassword.length !== 0) {
      data = {
        ...data,
        password: values.password,
        confirmPassword: values.confirmPassword,
      };
      if (values.password !== values.confirmPassword) {
        toast.info("Vos mots de passe ne coincident pas", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        console.log("correct data with password", data);
        dispatch(updateProfile({ token: token, data: data }))
          .unwrap()
          .then(() => {
            navigate("/profile");
            dispatch(getUser({ token: token }));
            toast.success("Profile mis à jour", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          })
          .catch((err) => {
            toast.error(err.msg, {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      }
    } else {
      console.log("data without password", data);
      dispatch(updateProfile({ token: token, data: data }))
        .unwrap()
        .then(() => {
          navigate("/profile");
          dispatch(getUser({ token: token }));
          toast.success("Profile mis à jour", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .catch((err) => {
          toast.error(err.msg, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };

  useEffect(() => {
    if (!loading && Object.keys(user).length === 0) {
      navigate(`/profile`);
    }
  }, [loading, navigate, user]);
  return (
    <div id="register">
      <div className="container--auth">
        <h1>Modifiez votre profil</h1>
        <form className="form" onSubmit={handleUpdate}>
          <div className="line">
            <label>Nom complèt</label>
            <input
              type="text"
              placeholder="Entrez votre nom complèt"
              value={values.name}
              onChange={(e) => setValues({ ...values, name: e.target.value })}
              required
              style={{textTransform:"capitalize"}}
            />
          </div>
          <div className="line">
            <div className="line-boxes">
              <div className="line--box">
                <label>Email</label>
                <input
                  type="email"
                  placeholder="Entrez votre email"
                  value={values.email}
                  onChange={(e) =>
                    setValues({ ...values, email: e.target.value })
                  }
                  disabled
                />
              </div>
              <div className="line--box">
                <label>Téléphone</label>
                <input
                  type="number"
                  placeholder="Entrez votre numéro téléphone"
                  value={values.phone}
                  onChange={(e) =>
                    setValues({ ...values, phone: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <div className="line">
            <label>Société</label>
            <input
              type="text"
              placeholder="Entrez le nom de votre société"
              value={values.company}
              onChange={(e) =>
                setValues({ ...values, company: e.target.value })
              }
              required
            />
          </div>
          <div className="line">
            <label>Addresse</label>
            <input
              type="adsress"
              placeholder="Entrez votre addresse"
              value={values.address}
              onChange={(e) =>
                setValues({ ...values, address: e.target.value })
              }
              required
            />
          </div>
          <div className="line">
            <label>Mot de passe</label>
            <input
              type="password"
              id="password"
              placeholder="Entrez votre mot de passe"
              value={values.password}
              onChange={(e) =>
                setValues({ ...values, password: e.target.value })
              }
            />
            {!showPassword ? (
              <Icons.BsEye className="eye" onClick={seePassword} />
            ) : (
              <Icons.BsEyeSlash className="eye" onClick={seePassword} />
            )}
          </div>
          <div className="line">
            <label>Répétez votre mot de passe</label>
            <input
              type="password"
              id="confirmPassword"
              placeholder="Entrez votre mot de passe"
              value={values.confirmPassword}
              onChange={(e) =>
                setValues({ ...values, confirmPassword: e.target.value })
              }
            />
            {!showConfirmPassword ? (
              <Icons.BsEye className="eye" onClick={seeConfirmPassword} />
            ) : (
              <Icons.BsEyeSlash className="eye" onClick={seeConfirmPassword} />
            )}
          </div>
          <div className="buttons">
            <button
              className="btn--main"
              type="submit"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              Confirmer
              {loading ? "..." : <Icons.BsArrowRightCircle className="icon" />}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateProfile;
